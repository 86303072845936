export const roomGroupAmenities = {
  "air-conditioning": {
    icon: "/stay_hotel_img/amenities/air-conditioning.svg",
    label: "Air conditioning",
  },
  "desk": {
    icon: "/stay_hotel_img/amenities/Desk.svg",
    label: "Desk",
  },
  "hairdryer": {
    icon: "/stay_hotel_img/amenities/Hairdryer.svg",
    label: "Hairdryer",
  },
  "pets-allowed": {
    icon: "/stay_hotel_img/amenities/Pets-allowed.svg",
    label: "Pets allowed",
  },
  "private-bathroom": {
    icon: "/stay_hotel_img/amenities/private-bathroom.svg",
    label: "Private bathroom",
  },
  "safe": {
    icon: "/stay_hotel_img/amenities/safe.svg",
    label: "Safe",
  },
  "shower": {
    icon: "/stay_hotel_img/amenities/shower.svg",
    label: "Shower",
  },
  "soundproofing": {
    icon: "/stay_hotel_img/amenities/mute.svg",
    label: "Soundproofing",
  },
  "telephone": {
    icon: "/stay_hotel_img/amenities/telephone.svg",
    label: "Telephone",
  },
  "towels": {
    icon: "/stay_hotel_img/amenities/Towel.svg",
    label: "Towels",
  },
  "tv": {
    icon: "/stay_hotel_img/amenities/tv.svg",
    label: "TV",
  },
  "wi-fi": {
    icon: "/stay_hotel_img/amenities/free-wifi.svg",
    label: "Free WiFi",
  },
  "high-speed-internet-access": {
    icon: "/stay_hotel_img/amenities/high-speed-internet-access.svg",
    label: "High speed internet",
  },
  "beach": {
    icon: "/stay_hotel_img/amenities/Beach.svg",
    label: "Beach",
  },
  "beach-access": {
    icon: "/stay_hotel_img/amenities/Beach-access.svg",
    label: "Beach access",
  },
  "blackout-blinds": {
    icon: "/stay_hotel_img/amenities/Blackout-blinds.svg",
    label: "Blackout Blinds",
  },
  "fitness": {
    icon: "/stay_hotel_img/amenities/Fitness.svg",
    label: "Fitness",
  },
  "iron": {
    icon: "/stay_hotel_img/amenities/Iron.svg",
    label: "Iron",
  },
  "mini-bar": {
    icon: "/stay_hotel_img/amenities/Mini-bar.svg",
    label: "Mini Bar",
  },
  "mirror": {
    icon: "/stay_hotel_img/amenities/Mirror.svg",
    label: "Mirror",
  },
  "pillows": {
    icon: "/stay_hotel_img/amenities/Pillows.svg",
    label: "Pillows",
  },
  "slippers": {
    icon: "/stay_hotel_img/amenities/Slippers.svg",
    label: "Slippers",
  },
  "tea": {
    icon: "/stay_hotel_img/amenities/tea.svg",
    label: "Tea",
  },
  "tea-or-coffee": {
    icon: "/stay_hotel_img/amenities/tea-or-coffee.svg",
    label: "Tea-or-Coffee",
  },
  "water": {
    icon: "/stay_hotel_img/amenities/water.svg",
    label: "Water",
  },
  "mosquito": {
    icon: "/stay_hotel_img/amenities/Mosquito.svg",
    label: "Mosquito",
  },
  "with-view": {
    icon: "/stay_hotel_img/amenities/With-view.svg",
    label: "With-View",
  },
  "toiletries": {
    icon: "/stay_hotel_img/amenities/toiletries.svg",
    label: "Toiletries",
  },
  "fridge": {
    icon: "/stay_hotel_img/amenities/Fridge.svg",
    label: "Fridge",
  },
  "heating": {
    icon: "/stay_hotel_img/amenities/Heating.svg",
    label: "Heating",
  },
  "hypoallergenic": {
    icon: "/stay_hotel_img/amenities/Hypoallergenic.svg",
    label: "Hypoallergenic",
  },
  "bathrobe": {
    icon: "/stay_hotel_img/amenities/Bathrobe.svg",
    label: "Bathrobe",
  },
  "coffee": {
    icon: "/stay_hotel_img/amenities/Coffee.svg",
    label: "Coffee",
  },
  "wardrobe": {
    icon: "/stay_hotel_img/amenities/Wardrobe.svg",
    label: "Wardrobe",
  },
  "bedsheets": {
    icon: "/stay_hotel_img/amenities/Bedsheets.svg",
    label: "Bedsheets",
  },
  "balcony": {
    icon: "/stay_hotel_img/amenities/Balcony.svg",
    label: "Balcony",
  },
  "dining-area": {
    icon: "/stay_hotel_img/amenities/Dining area.svg",
    label: "Dining area",
  },
  "sauna": {
    icon: "/stay_hotel_img/amenities/Sauna.svg",
    label: "Sauna",
  },
  "fireplace": {
    icon: "/stay_hotel_img/amenities/Fireplace.svg",
    label: "Fireplace",
  },
  "fan": {
    icon: "/stay_hotel_img/amenities/Fan.svg",
    label: "Fan",
  },
  "kitchen": {
    icon: "/stay_hotel_img/amenities/Kitchen.svg",
    label: "Kitchen",
  },
  "microwave": {
    icon: "/stay_hotel_img/amenities/Microwave.svg",
    label: "Microwave",
  },
  "addon-service": {
    icon: "/stay_hotel_img/amenities/Addon service.svg",
    label: "Addon Service",
  }
};

export const hotelFacilities = {
  "General": {
    icon: "/stay_hotel_img/facilities/general.svg",
    label: "General",
  },
  "Rooms": {
    icon: "/stay_hotel_img/facilities/Rooms.svg",
    label: "Rooms",
  },
  "Accessibility": {
    icon: "/stay_hotel_img/facilities/Accessibility.svg",
    label: "Accessibility",
  },
  "Services and amenities": {
    icon: "/stay_hotel_img/facilities/services_and_amenities.svg",
    label: "Services and amenities",
  },
  "Meals": {
    icon: "/stay_hotel_img/facilities/Meals.svg",
    label: "Meals",
  },
  "Internet": {
    icon: "/stay_hotel_img/facilities/Internet.svg",
    label: "Internet",
  },
  "Transfer": {
    icon: "/stay_hotel_img/facilities/Transfer.svg",
    label: "Transfer",
  },
  "Languages Spoken": {
    icon: "/stay_hotel_img/facilities/languages_spoken.svg",
    label: "Languages Spoken",
  },
  "Tourist services": {
    icon: "/stay_hotel_img/facilities/Tourist services.svg",
    label: "Tourist services",
  },
  "Recreation": {
    icon: "/stay_hotel_img/facilities/Recreation.svg",
    label: "Recreation",
  },
  "Parking": {
    icon: "/stay_hotel_img/facilities/parking.svg",
    label: "Parking",
  },
  "Pool and beach": {
    icon: "/stay_hotel_img/facilities/Pool and beach.svg",
    label: "Pool and beach",
  },
  "Business": {
    icon: "/stay_hotel_img/facilities/business.svg",
    label: "Business",
  },
  "Sports": {
    icon: "/stay_hotel_img/facilities/Sports.svg",
    label: "Sports",
  },
  "Beauty and wellness": {
    icon: "/stay_hotel_img/facilities/beauty_and_wellness.svg",
    label: "Beauty and wellness",
  },
  "Kids": {
    icon: "/stay_hotel_img/facilities/Kids.svg",
    label: "Kids",
  },
  "Pets": {
    icon: "/stay_hotel_img/facilities/pets.svg",
    label: "Pets",
  },
  "Health and Safety Measures": {
    icon: "/stay_hotel_img/facilities/Health care.svg",
    label: "Health care",
  },
}

export const searchResultAmenities = {
  "ATM": {
    icon: "/stay_search_img/amenities/ATM.svg",
  },
  "Air conditioning": {
    icon: "/stay_search_img/amenities/Air conditioning.svg"
  },
  "24-hour reception": {
    icon: "/stay_search_img/amenities/24-hour reception.svg"
  },
  "Elevator/lift": {
    icon: "/stay_search_img/amenities/Elevator-lift.svg"
  },
  "Smoke-free property": {
    icon: "/stay_search_img/amenities/Smoke-free property.svg"
  },
  "Smoking areas": {
    icon: "/stay_search_img/amenities/Smoking areas.svg"
  },
  "Smoking Allowed": {
    icon: "/stay_search_img/amenities/Smoking Allowed.svg"
  },
  "All Spaces Non-Smoking (public and private)": {
    icon: "/stay_search_img/amenities/Smoke-free property.svg"
  },
  "Computer": {
    icon: "/stay_search_img/amenities/Computer.svg"
  },
  "Currency exchange": {
    icon: "/stay_search_img/amenities/Currency exchange.svg"
  },
  "Heating": {
    icon: "/stay_search_img/amenities/Heating.svg"
  },
  "Security guard": {
    icon: "/stay_search_img/amenities/Security guard.svg"
  },
  "Newspapers": {
    icon: "/stay_search_img/amenities/Newspapers.svg"
  },
  "Garden": {
    icon: "/stay_search_img/amenities/Garden.svg"
  },
  "Terrace": {
    icon: "/stay_search_img/amenities/Terrace.svg"
  },
  "Design hotel": {
    icon: "/stay_search_img/amenities/Design hotel.svg"
  },
  "Doorman": {
    icon: "/stay_search_img/amenities/Doorman.svg"
  },
  "Fire Extinguisher": {
    icon: "/stay_search_img/amenities/Fire Extinguisher.svg"
  },
  "Outdoor furniture": {
    icon: "/stay_search_img/amenities/Outdoor furniture.svg"
  },
  "Upper floors accessible by elevator": {
    icon: "/stay_search_img/amenities/Upper floors accessible by elevator.svg"
  },
  "Electric car charging": {
    icon: "/stay_search_img/amenities/Electric car charging.svg"
  },
  "Shopping on site": {
    icon: "/stay_search_img/amenities/Shopping on site.svg"
  },
  "Property": {
    icon: "/stay_search_img/amenities/Property.svg"
  },
  "Ticket assistance": {
    icon: "/stay_search_img/amenities/Ticket assistance.svg"
  },
  "Gift shop": {
    icon: "/stay_search_img/amenities/Gift shop.svg"
  },
  "Reception desk": {
    icon: "/stay_search_img/amenities/24-hour reception.svg"
  },
  "No elevators": {
    icon: "/stay_search_img/amenities/No elevators.svg"
  },
  "Express check-in/check-out": {
    icon: "/stay_search_img/amenities/check-in_check-out.svg"
  },
  "Private check-in/check-out": {
    icon: "/stay_search_img/amenities/check-in_check-out.svg"
  },
  "Express check-in": {
    icon: "/stay_search_img/amenities/Express check-in.svg"
  },
  "Early check-in": {
    icon: "/stay_search_img/amenities/Early check-in.svg"
  },
  "Express check-out": {
    icon: "/stay_search_img/amenities/Express check-in.svg"
  },
  "Late check-out": {
    icon: "/stay_search_img/amenities/Late check-out.svg"
  },
  "Television in lobby": {
    icon: "/stay_search_img/amenities/Television in lobby.svg"
  },
  "Patio": {
    icon: "/stay_search_img/amenities/Patio.svg"
  },
  "Adults Only": {
    icon: "/stay_search_img/amenities/Adults Only.svg"
  },
  "Washing machine": {
    icon: "/stay_search_img/amenities/Washing machine.svg"
  },
  "Dryer": {
    icon: "/stay_search_img/amenities/Dryer.svg"
  },
  "Chapel": {
    icon: "/stay_search_img/amenities/Chapel.svg"
  },
  "Pharmacy": {
    icon: "/stay_search_img/amenities/Pharmacy.svg"
  }
}
