import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { OrderStatusState } from "../../Interfaces/reduxInterfaces";
import axiosInstance from "../../Utils/axiosConfig";

const initialState: OrderStatusState = {
  status: null,
  error: null,
  loading: false,
};

export const fetchOrderStatus = createAsyncThunk<string, string>(
  "orderStatus/fetchStatus",
  async (orderId) => {
    const response = await axiosInstance.get(`/dev/orderStatus/${orderId}`);
    return response.data;
  }
);

const orderStatusSlice = createSlice({
  name: "orderStatus",
  initialState,
  reducers: {
    resetOrderStatus: (state) => {
      state.status = null;
      state.error = null;
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchOrderStatus.fulfilled,
        (state, action: PayloadAction<string>) => {
          state.status = action.payload;
          state.loading = false;
        }
      )
      .addCase(fetchOrderStatus.rejected, (state, action) => {
        state.error = action.error.message || "Something went wrong";
        state.loading = false;
      });
  },
});

export const { resetOrderStatus } = orderStatusSlice.actions;
export default orderStatusSlice.reducer;
