import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axiosInstance from '../Utils/axiosConfig';
import { RootState } from '../Redux/store';
import {
  setHotelInfo,
  setFetchStatus,
  setError,
  clearHotelInfo,
} from '../Redux/Slices/hotelInfoSlice';
import Stepper from '../Components/Shared/stepper';
import HotelInfoSkeleton from '../Components/Skeleton/hotelInfoSkeleton';
import HotelDetails from '../Components/Views/Hotel/hotelRoomSection';
import HotelHouseRules from '../Components/Views/Hotel/hotelHouseRules';
import HotelFacilities from '../Components/Views/Hotel/hotelFacilities';
import HotelReviews from '../Components/Views/Hotel/hotelReviews';

const Hotel: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  // Access hotelInfo data and other state from Redux store
  const hotelInfoData = useSelector((state: RootState) => state.hotelInfo.data);
  const fetchStatus = useSelector((state: RootState) => state.hotelInfo.status);
  const error = useSelector((state: RootState) => state.hotelInfo.error);

  const [mismatchError, setMismatchError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [groupedRates, setGroupedRates] = useState([]);
  const [roomCount, setRoomCount] = useState(0);
  const [hotelId, setHotelId] = useState('');

  const params = new URLSearchParams(location.search);
  const urlCheckin = params.get("checkin") || "";
  const urlCheckout = params.get("checkout") || "";
  const urlGroup = params.get("group") || '';
  const urlHid = params.get("hid") || "";
  const urlName = params.get("name") || "";
  const urlCode = params.get("code") || "";
  const urlGuests = params.get("guests");
  const urlResidency = params.get("residency") || "";

  // /* eslint-disable */
  useEffect(() => {
    if (!urlCheckin || !urlCheckout || !urlHid || !urlName || !urlGroup || !urlCode || !urlGuests || !urlResidency) {
      dispatch(clearHotelInfo());
      setMismatchError(true);
      return;
    }

    // Date validation
    const checkinDate = new Date(urlCheckin).getTime();
    const checkoutDate = new Date(urlCheckout).getTime();
    if (isNaN(checkinDate) || isNaN(checkoutDate) || checkinDate > checkoutDate) {
      dispatch(clearHotelInfo());
      setMismatchError(true);
      return;
    }

    const parsedGuests = JSON.parse(urlGuests);
    const isValidGuests = Array.isArray(parsedGuests) && parsedGuests.every(guest =>
      guest.hasOwnProperty("rooms") &&
      guest.hasOwnProperty("adults") &&
      guest.hasOwnProperty("children")
    );
    if (!isValidGuests) {
      dispatch(clearHotelInfo());
      setMismatchError(true);
      return;
    };

    setMismatchError(false);
    const fetchSingleHotelDetails = async () => {
      dispatch(clearHotelInfo());
      setLoading(true);
      dispatch(setFetchStatus('loading'));

      try {
        const response = await axiosInstance.get(
          '/hotelDetails/singleRoomDetails',
          {
            params: {
              hid: Number(urlHid),
              checkin: urlCheckin,
              checkout: urlCheckout,
              guests: urlGuests,
              residency: urlResidency
            },
          },
        );

        if (response && response.data.status === 'success') {
          const data = response.data.data;
          dispatch(setHotelInfo(data?.hotelInfo));
          setHotelId(data?.hotelInfo?.id);
          setGroupedRates(data?.groupedRates);
          setRoomCount(data?.roomCount);
          dispatch(setFetchStatus('succeeded'));
          setLoading(false);
        } else {
          dispatch(setFetchStatus('failed'));
          setLoading(false);
        }
      } catch (error) {
        dispatch(setError('Error fetching hotel details'));
        dispatch(setFetchStatus('failed'));
        setLoading(false);
      } finally {
        setLoading(false);
        dispatch(setFetchStatus('idle'));
      }
    };

    fetchSingleHotelDetails();
  }, [dispatch, urlHid, urlCheckin, urlCheckout, urlName, urlGroup, urlCode, urlGuests, urlResidency]);

  return (
    <>
      <div className='stay-middle-div bg-[#F8F8F8]'>
        <div className='w-full xl:container mx-auto px-[1rem] md:px-[1.5rem] mx-w-[1536px]'>
          <div className='py-4 md:py-10'>
            <Stepper />
          </div>
          {!loading && fetchStatus !== 'loading' ? (
            <>
              {hotelInfoData ? (
                <>
                  <HotelDetails
                    data={hotelInfoData}
                    hotelRoomData={groupedRates}
                    roomCount={roomCount}
                  />
                  <HotelHouseRules data={hotelInfoData} />
                  <HotelReviews hotelId={hotelId} />
                  <HotelFacilities data={hotelInfoData} /></>
              ) : (
                <div className="text-center text-red-900 flex flex-col gap-2 py-8">
                  <p>No search result found.</p>
                  {mismatchError && <p>
                    The provided Hotel details does not match your search criteria. Please check your search parameters and try again.
                  </p>}
                  {error && <p>{error}</p>}
                </div>
              )}
            </>
          ) : (
            <HotelInfoSkeleton />
          )}
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Hotel;
