import React, { useState } from 'react';
import { Tooltip } from '@mui/material';

import { searchResultAmenities } from '../../Shared/allImages';
import { HotelDetailsCardProps } from '../../../Interfaces/componentInterfaces';

const HotelDetailsCard: React.FC<HotelDetailsCardProps> = ({ data }) => {
    const [showAllAmenities, setShowAllAmenities] = useState(false);

    const displayedAmenities = showAllAmenities ? data.amenities : data.amenities.slice(0, 4);
    const toggleShowAllAmenities = () => {
        setShowAllAmenities(prevState => !prevState);
    };

    return (
        <>
            <div className='hidden md:block'>
                <div className="flex gap-1 w-full h-full rounded-xl bg-[#FFFFFF] shadow-md">
                    <div className="flex w-auto h-[220px] rounded-bl-lg rounded-tl-lg">
                        <img className="rounded-bl-lg rounded-tl-lg object-cover" src={data.image} alt="" />
                    </div>

                    <div className='flex justify-between p-4 w-full'>
                        <div className="w-full flex flex-col gap-2">
                            <div className='flex flex-col gap-1'>
                                <div className="flex items-center gap-2">
                                    {[...Array(data?.star)].map((_, index) => (
                                        <img
                                            key={`filled-${index}`}
                                            className='w-[12px] h-[12px]'
                                            src='/stay_search_img/s-star.svg'
                                            alt='filled star'
                                        />
                                    ))}
                                    {[...Array(5 - data?.star)].map((_, index) => (
                                        <img
                                            key={`empty-${index}`}
                                            className='w-[14px] h-[14px]'
                                            src='/stay_search_img/s-star-blank.svg'
                                            alt='empty star'
                                        />
                                    ))}
                                </div>
                                <div className=''>
                                    <p className="text-[#000F2E] text-[24px] font-bold leading-8">
                                        {data?.name?.length > 80
                                            ? `${data.name.substring(0, 78)}..`
                                            : data.name
                                        }
                                    </p>
                                </div>
                                {/* <div className="flex items-center gap-3">
                                    <div className="w-[30px] h-[29px] bg-[#002E8A] justify-center text-white rounded-md items-center flex px-1">{data.rating}</div>
                                    <p className="text-[14px]">({data.reviews})</p>
                                </div> */}
                            </div>
                            <div className='flex flex-col gap-4'>
                                <div className="flex items-center gap-2">
                                    <img className="w-[21px] h-[22px]" src="/stay_search_img/map-pin.svg" alt="" />
                                    <div className="flex flex-col">
                                        <p className="text-[14px]">
                                            {data?.address?.length > 80
                                                ? `${data.address.substring(0, 78)}..`
                                                : data.address
                                            }
                                        </p>
                                        <p className="text-[12px]">{data.distance}</p>
                                    </div>
                                </div>
                                <div className="flex w-full items-center flex-wrap gap-5 pt-3">
                                    {displayedAmenities.map((amenity, index) => (
                                        <Tooltip key={index} title={amenity} arrow>
                                            <div>
                                                <img
                                                    className="w-[22px] h-[22px]"
                                                    src={(searchResultAmenities as any)[amenity]?.icon || '/stay_search_img/amenities/check.svg'}
                                                    alt={amenity}
                                                />
                                            </div>
                                        </Tooltip>
                                    ))}
                                    {data.amenities.length > 4 && (
                                        <div className="flex">
                                            <button onClick={toggleShowAllAmenities} className='flex items-center gap-2'>
                                                <p className="text-[12px]">{showAllAmenities ? "Show less" : "Show all"}</p>
                                                <img className="w-[20px] h-[20px]" src="/stay_search_img/expand.svg" alt="" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Mobile view */}
            <div className='flex flex-col w-full gap-4 shadow-md rounded-lg md:hidden p-3 bg-[#FFFFFF]'>
                <div className='flex items-center gap-3'>
                    <div className="flex max-w-[120px] h-[70px]">
                        <img className="rounded-md" src={data.image} alt="" />
                    </div>
                    <div className='flex flex-col'>
                        <div className=''>
                            <p className="text-[#000F2E] text-[18px] font-bold">{data.name}</p>
                        </div>
                        <div className="flex items-center gap-2">
                            {[...Array(data?.star)].map((_, index) => (
                                <img
                                    key={`filled-${index}`}
                                    className='w-[18px] h-[18px]'
                                    src='/stay_search_img/rating-star-fill.svg'
                                    alt='filled star'
                                />
                            ))}
                            {[...Array(5 - data?.star)].map((_, index) => (
                                <img
                                    key={`empty-${index}`}
                                    className='w-[18px] h-[18px]'
                                    src='/stay_search_img/rating-star-blank.svg'
                                    alt='empty star'
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className='flex items-center w-full justify-between gap-4'>
                    {/* <div className="flex items-center gap-3">
                        <div className="w-[30px] h-[30px] bg-[#002E8A] justify-center text-white rounded-sm items-center flex px-2">{data.rating}</div>
                        <div className='flex flex-col'>
                            <p className='text-[12px] font-bold'>Excellent</p>
                            <p className="text-[14px]">({data.reviews})</p>
                        </div>
                    </div> */}

                    <div className="flex items-center gap-3">
                        <img className="w-[18px] h-[20px]" src="/stay_search_img/map-pin.svg" alt="" />
                        <div className="flex flex-col">
                            <p className="text-[12px]">
                                {data?.address?.length > 80
                                    ? `${data.address.substring(0, 78)}..`
                                    : data.address
                                }
                            </p>
                            <p className="text-[12px]">{data.distance}</p>
                        </div>
                    </div>
                </div>
                <div className='flex items-center w-full justify-between'>
                    <div className="flex flex-col gap-6 w-full">
                        <div className='flex items-center flex-wrap gap-3'>
                            {displayedAmenities.map((amenity, index) => (
                                <Tooltip key={index} title={amenity} arrow>
                                    <div>
                                        <img
                                            className="w-[18px] h-[18px]"
                                            src={(searchResultAmenities as any)[amenity]?.icon || '/stay_search_img/amenities/check.svg'}
                                            alt={amenity}
                                        />
                                    </div>
                                </Tooltip>
                            ))}
                        </div>
                        {data.amenities.length > 4 && (
                            <div className='flex'>
                                <button onClick={toggleShowAllAmenities} className='flex items-center gap-3'>
                                    <span className="text-[12px]">{showAllAmenities ? "Show less" : "Show all"}</span>
                                    <img className="w-[16px] h-[16px]" src="/stay_search_img/expand.svg" alt="" />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default HotelDetailsCard;