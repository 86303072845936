import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { validateForm } from "../Redux/Slices/userSlice";
import { RootState, AppDispatch } from "../Redux/store";
import axiosInstance from "../Utils/axiosConfig";
import Stepper from "../Components/Shared/stepper";
import InputGuestDetails from "../Components/Views/GuestDetails/inputGuestDetails";
import BookingDetails from "../Components/Views/GuestDetails/bookingDetails";
import PriceSummary from "../Components/Views/GuestDetails/priceSummary";
import HotelDetailsCard from "../Components/Views/GuestDetails/hotelDetailsCard";
import Coupon from "../Components/Views/GuestDetails/coupon";
import HotelDetailsCardSkeleton from "../Components/Skeleton/hotelDetailsCardSkeleton";
import { Hotel } from "../Interfaces/componentInterfaces";

const GuestDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const params = new URLSearchParams(location.search);
  const urlHid = params.get("hid") || "";
  const urlBookHash = params.get("book_hash") || "";
  const urlCheckin = params.get("checkin") || "";
  const urlCheckout = params.get("checkout") || "";
  const urlGuests = params.get("guests") || "";
  const urlResidencyCode = params.get("residency") || "";
  const { userDetails, error } = useSelector((state: any) => state.user);
  const { selectedCouponId, couponError } = useSelector(
    (state: RootState) => state.coupon
  );

  const { selectedCryptoWithNetwork, selectedFiat } = useSelector(
    (state: any) => state.currency
  );
  const { data: hotelInfo } = useSelector((state: any) => state.hotelInfo);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);

  const [isPressed, setIsPressed] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [localHotelInfo, setLocalHotelInfo] = useState<any>({});
  const [gettingResponseError, setGettingResponseError] = useState<any>(null);
  const [prebookStatus, setPrebookStatus] = useState("loading");
  const [prebookData, setPrebookData] = useState<any>({});
  const [formError, setFormError] = useState("");
  const [mismatchError, setMismatchError] = useState(false);

  useEffect(() => {
    if (!urlHid) {
      setMismatchError(true);
      return;
    }

    setMismatchError(false);
    const fetchHotelInfo = async () => {
      setLoadingInfo(true);
      try {
        const response = await axiosInstance.get("/hotelDetails/hotelInfo", {
          params: {
            hid: urlHid,
          },
        });
        const dataFetch = response.data;
        setLocalHotelInfo(dataFetch.data);
      } catch (error) {
        console.log("Error fetching availability:", error);
      } finally {
        setLoadingInfo(false);
      }
    };
    fetchHotelInfo();
  }, [urlHid]);

  const mapDataToProps = (item: Hotel) => {
    const roomAmenities = item?.amenity_groups?.find(
      (group) => group.group_name === "General"
    )?.amenities;
    // const roomDummyAmenities = ["wifi", "s-no-smoke", "corn", "pc", "gym"];
    return {
      image: item?.images?.[0]?.replace("{size}", "1024x768") || "",
      star: item?.star_rating || 0,
      rating: item?.rating || 0,
      reviews: item?.reviews || 0,
      name: item?.name || "No name available",
      address: item?.address || "No address available",
      distance: item?.distance || "",
      amenities: roomAmenities || [],
      id: item?.id || "",
    };
  };

  const totalGuest = 0;

  useEffect(() => {
    if (
      !urlHid ||
      !urlCheckin ||
      !urlCheckout ||
      !urlGuests ||
      !urlResidencyCode
    ) {
      setMismatchError(true);
      return;
    }

    // Date validation
    const checkinDate = new Date(urlCheckin).getTime();
    const checkoutDate = new Date(urlCheckout).getTime();
    if (
      isNaN(checkinDate) ||
      isNaN(checkoutDate) ||
      checkinDate > checkoutDate
    ) {
      setMismatchError(true);
      return;
    }

    // Guests validation
    const parsedGuests = JSON.parse(urlGuests);
    const isValidGuests =
      Array.isArray(parsedGuests) &&
      parsedGuests.every(
        (guest) =>
          guest.hasOwnProperty("rooms") &&
          guest.hasOwnProperty("adults") &&
          guest.hasOwnProperty("children")
      );
    if (!isValidGuests) {
      setMismatchError(true);
      return;
    }

    setMismatchError(false);
    if (urlBookHash) {
      const fetchPrebookInfo = async () => {
        try {
          const body = {
            checkin: urlCheckin,
            checkout: urlCheckout,
            guests: urlGuests,
            residency: urlResidencyCode,
            book_hash: urlBookHash,
          };
          const response = await axiosInstance.post(
            `/hotelDetails/prebook`,
            body
          );
          const dataFetch = response.data;
          setPrebookData(dataFetch.data);
          setPrebookStatus("success");
        } catch (error) {
          console.log("Error", error);
          toast.error(
            "Regrettably, the selected offer is no longer available. Please consider choosing a different room from the same hotel."
          );
          setPrebookStatus("error");
          setTimeout(() => {
            navigate("/");
          }, 4000);
        }
      };
      fetchPrebookInfo();
    } else {
      toast.error(
        "Regrettably, the selected offer is no longer available. Please consider choosing a different room from the same hotel."
      );
      setPrebookStatus("error");
      setTimeout(() => {
        navigate("/");
      }, 4000);
    }
  }, [
    navigate,
    setPrebookStatus,
    urlBookHash,
    urlCheckin,
    urlCheckout,
    urlGuests,
    urlResidencyCode,
    urlHid,
  ]);

  const handlePaymentProceed = async () => {
    setButtonLoading(true);

    const validationErrors = await dispatch(validateForm()).unwrap();

    if (Object.keys(validationErrors).length > 0 || couponError !== null) {
      setButtonLoading(false);
      setFormError("Please ensure the required fields are completed.");
      console.log("Validation failed: ", validationErrors);
      return;
    }

    // If no errors, proceed with creating the request body for the API call
    setFormError("");
    const body = {
      userDetails,
      book_hash: urlBookHash,
      financialInfo: {
        fiatDetails: {
          selectedFiat,
        },
        cryptoDetails: {
          selectedCrypto: selectedCryptoWithNetwork.cryptoName,
          selectedCryptoNetwork: selectedCryptoWithNetwork.blockchain2,
        },
      },
      s_id: prebookData.s_id,
      coupon: selectedCouponId,
    };

    try {
      const response = await axiosInstance.post("/generateWallet", body);
      const data = response.data;

      if (data.orderId && data.phone_number) {
        navigate(
          `/payment?orderId=${data.orderId}&phone_number=${data.phone_number}`
        );
      } else {
        throw new Error(data.message || "Failed to generate address");
      }
    } catch (error: any) {
      setGettingResponseError(error?.response?.data?.error);
    } finally {
      setButtonLoading(false);
    }
  };

  const authToken = localStorage.getItem("authToken");
  if (!authToken) {
    return <Navigate to="/login" replace />;
  }

  return (
    <>
      <div className="bg-[#F8F8F8]">
        <div className="w-full xl:container mx-auto px-[1rem] md:px-[1.5rem] mx-w-[1536px]">
          <div className="py-4 md:py-10">
            <Stepper />
          </div>

          {!mismatchError ? (
            <div className="grid lg:grid-cols-[64%_34%] gap-5 mt-2">
              <div className="">
                {loadingInfo ? (
                  <HotelDetailsCardSkeleton />
                ) : (
                  <HotelDetailsCard data={mapDataToProps(localHotelInfo)} />
                )}
                <InputGuestDetails />
              </div>

              <div>
                <BookingDetails hotelInfo={hotelInfo} totalGuest={totalGuest} />
                <Coupon
                  prebookStatus={prebookStatus}
                  buttonLoading={buttonLoading}
                />
                <PriceSummary
                  prebookStatus={prebookStatus}
                  prebookData={prebookData}
                />

                <div className="w-full my-5">
                  <button
                    onMouseDown={() => setIsPressed(true)}
                    onMouseUp={() => setIsPressed(false)}
                    onClick={handlePaymentProceed}
                    disabled={buttonLoading || prebookStatus !== "success"}
                    className={`rounded-md px-3 py-3 w-full font-medium bg-[#002E8A] text-[#FFFFFF] 
                    ${
                      buttonLoading || prebookStatus !== "success"
                        ? "opacity-50 cursor-not-allowed"
                        : isPressed
                        ? "bg-[#000B81]"
                        : "opacity-100 hover:bg-[#4826a3]"
                    }`}
                  >
                    {buttonLoading || prebookStatus !== "success" ? (
                      <CircularProgress size={18} color="inherit" />
                    ) : (
                      `Review details and pay with ${selectedCryptoWithNetwork?.cryptoName}`
                    )}
                  </button>
                  {error && <p>Error: {error}</p>}
                  {gettingResponseError && <p>Error: {gettingResponseError}</p>}
                  <p>{formError}</p>

                  <div className="mt-3">
                    <p>
                      Please read the{" "}
                      <a
                        className="text-blue-600 hover:text-blue-700 hover:border-blue-700 border-blue-600 border-b"
                        href="https://instacoins.travel/terms-conditions#changes-cancellations-refunds"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {" "}
                        cancellation policy
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="text-center text-red-900 flex flex-col gap-2 py-6">
              <p>No search result found.</p>
              <p>
                The provided guest details does not match your search criteria.
                Please check your search parameters and try again.
              </p>
            </div>
          )}
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default GuestDetails;
