import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Container, 
  TextField, 
  Typography, 
  InputAdornment, 
  IconButton, 
  CircularProgress 
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import axiosInstance, { setAuthToken } from '../Utils/axiosConfig';

const Login: React.FC = () => {
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axiosInstance.post('/auth', { password });
      const { token } = response.data;

      if (token) {
        setAuthToken(token);
        navigate('/');
      } else {
        setErrorMessage('Authentication failed. No token received.');
      }
    } catch (error) {
      setErrorMessage('Authentication failed. Please check your credentials.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='bg-[#909090] w-full h-[100vh]'>
      <Container
        component='main'
        maxWidth='xs'
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingTop: '10vh',
        }}
      >
        <div className='text-center flex flex-col w-full bg-slate-200 rounded-lg p-4 shadow-2xl'>
          <Typography component='h1' variant='h5'>
            Sign in
          </Typography>
          <form onSubmit={handleLogin} style={{ width: '100%', marginTop: 1 }}>
            <TextField
              variant='outlined'
              margin='normal'
              required
              fullWidth
              name='password'
              label='Password'
              type={showPassword ? 'text' : 'password'}
              id='password'
              autoComplete='current-password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => setShowPassword((prev) => !prev)}
                      edge='end'
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <button
              type='submit'
              disabled={loading}
              className={`w-full p-2 text-[#FFFFFF] font-medium rounded-md mt-2 
                ${loading ? 'bg-[#a3aae6]' : 'bg-[#002E8A] hover:bg-[#4826a3]'}`}
            >
              {loading ? <CircularProgress size={20} color="inherit" /> : "Login"}
            </button>
            {errorMessage && (
              <Typography color='error' style={{ marginTop: '20px' }}>
                {errorMessage}
              </Typography>
            )}
          </form>
        </div>
      </Container>
    </div>
  );
};

export default Login;
