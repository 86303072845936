import React, { useState, useEffect, useMemo } from "react";
import { addDays, format } from 'date-fns';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Modal,
  IconButton,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { country } from "../../../Data/country";
import { Room } from "../../../Interfaces/componentInterfaces";
import CountryAutocomplete from "../../AutocompleteViews/countryAutocomplete";
import { setSearchParams } from "../../../Redux/Slices/searchVarSlice";
import { AppDispatch, RootState } from "../../../Redux/store";

const NextDestination: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [selectedRegion, setSelectedRegion] = useState<string>('Worldwide');
  const [selectedDestination, setSelectedDestination] = useState<any>(null);

  const searchVar = useSelector((state: RootState) => state.searchVar);
  const selectedResidency = searchVar.residency;
  const selectedResidencyCode = searchVar.residencyCode;

  const destinations = [
    { name: 'Paris', country: 'France', code: 'FR', group: 'Regions', type: 'City', region: 'Europe', region_id: '2734', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2F0b3129ea-65b9-4f0f-abc7-dc300f434897&w=3840&q=75' },
    { name: 'New York', country: 'United States of America', code: 'US', group: 'Regions', type: 'City', region: 'North America', region_id: '2621', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2Febdc95d8-105c-4d87-8200-eaf40a951a0e&w=3840&q=75' },
    { name: 'Madrid', country: 'Spain', code: 'ES', group: 'Regions', type: 'City', region: 'Europe', region_id: '2198', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2Fe3b514ee-d186-4d2d-b803-232fb918be9c&w=3840&q=75' },
    { name: 'Sydney', country: 'Australia', code: 'AU', group: 'Regions', type: 'City', region: 'Pacific Ocean and Australia', region_id: '3341', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2F9d2a9ded-0eb5-4ad0-a2dd-e88e935c227b&w=3840&q=75' },
    { name: 'Los Angeles', country: 'United States of America', code: 'US', group: 'Regions', type: 'City', region: 'North America', region_id: '2011', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2F76436dd4-8d7a-42fd-91aa-ad900e0095b2&w=3840&q=75' },
    { name: 'Abu Dhabi', country: 'United Arab Emirates', code: 'AE', group: 'Regions', type: 'City', region: 'Middle East', region_id: '453', image: 'https://test.instacoins.travel/_next/image?url=%2Fassets%2Fcc64ae9a-30f0-4f4f-8012-b844c3d61ef5&w=3840&q=75' },
  ];

  const filteredDestinations = selectedRegion === 'Worldwide'
    ? destinations
    : destinations.filter(destination => destination.region === selectedRegion);

  const page = 0;
  const today = new Date();
  const oneDaysLater = addDays(today, 10);
  const startDate = today;
  const endDate = oneDaysLater;
  const defaultRooms = [{ rooms: 1, adults: 2, children: [] }];

  const [loadings, setLoadings] = useState(false);
  const [rooms] = useState<Room[]>(defaultRooms);
  const [openCountrySelectModal, setOpenCountrySelectModal] = useState(false);
  const [inputValueCountry, setInputValueCountry] = useState<string>("");
  const [countrySelect, setCountrySelect] = useState<string>(selectedResidency ?? "");
  const [isoCode, setIsoCode] = useState<string>(selectedResidencyCode ?? "");

  const handleImageClick = (destination: any) => {
    if (countrySelect && isoCode) {
      searchWithRegion(destination);
    } else {
      setSelectedDestination(destination);
      handleCountrySelect();
    }
  };

  const handleCountrySelect = () => setOpenCountrySelectModal(true);
  const handleCloseCountrySelect = () => setOpenCountrySelectModal(false);
  const handleCountryChange = (event: React.SyntheticEvent, value: string) => {
    setInputValueCountry(value);
  };
  const handleCountryOptionChange = (event: any, newValue: any) => {
    if (newValue) {
      setCountrySelect(newValue?.name);
      setIsoCode(newValue?.code);
    } else {
      setCountrySelect("");
      setIsoCode("");
    }
  };

  const checkinDate = useMemo(
    () => (startDate ? format(startDate, 'yyyy-MM-dd') : 'N/A'),
    [startDate]
  );

  const checkoutDate = useMemo(
    () => (endDate ? format(endDate, 'yyyy-MM-dd') : 'N/A'),
    [endDate]
  );

  useEffect(() => {
    dispatch(
      setSearchParams({
        checkin: checkinDate !== 'N/A' ? checkinDate : null,
        checkout: checkoutDate !== 'N/A' ? checkoutDate : null,
        guests: rooms,
        group: selectedDestination?.group || "",
        name: selectedDestination?.name || "",
        regionId: selectedDestination?.region_id || "",
        hid: "",
        id: "",
        type: selectedDestination?.type || "",
        countryCode: selectedDestination?.code || "",
        residency: countrySelect,
        residencyCode: isoCode,
      })
    );
  }, [
    dispatch,
    checkinDate,
    checkoutDate,
    rooms,
    selectedDestination,
    countrySelect,
    isoCode,
  ]);

  const searchWithRegion = (destination: any) => {
    try {
      setLoadings(true);
      const queryParams = new URLSearchParams({
        page: page.toString(),
        checkin: checkinDate,
        checkout: checkoutDate,
        group: destination?.group,
        regionId: destination?.region_id,
        name: destination?.name,
        type: destination?.type,
        code: destination?.code,
        guests: JSON.stringify(rooms),
        residency: selectedResidencyCode
      }).toString();

      navigate(`/hotelsByRegion?${queryParams}`, {
      });
    } catch (error) {
      console.error('Failed to fetch search data:', error);
    } finally {
      setLoadings(false);
    }
  }

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "auto",
    borderRadius: "8px",
  };

  return (
    <>
      <div className="next-destination-div ">
        <div className="div-heading mb-5">
          <h5 className="heading">Pick your next destination</h5>
          <p className="sub-heading">
            Search Flights to our most popular destinations
          </p>
        </div>

        <div className="overflow-x-auto hides-scrollbar">
          <div className="flex items-center gap-2 py-2 w-[1450px] md:justify-center md:flex-wrap md:w-full md:gap-4">
            <button onClick={() => setSelectedRegion('Worldwide')} className={`destination-btn ${selectedRegion === 'Worldwide' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Worldwide</p>
            </button>
            <button onClick={() => setSelectedRegion('Europe')} className={`destination-btn ${selectedRegion === 'Europe' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Europe</p>
            </button>
            <button onClick={() => setSelectedRegion('North America')} className={`destination-btn ${selectedRegion === 'North America' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>North America</p>
            </button>
            <button onClick={() => setSelectedRegion('Asia')} className={`destination-btn ${selectedRegion === 'Asia' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Asia</p>
            </button>
            <button onClick={() => setSelectedRegion('Middle East')} className={`destination-btn ${selectedRegion === 'Middle East' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Middle East</p>
            </button>
            <button onClick={() => setSelectedRegion('Pacific Ocean and Australia')} className={`destination-btn ${selectedRegion === 'Pacific Ocean and Australia' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Pacific Ocean and Australia</p>
            </button>
            <button onClick={() => setSelectedRegion('South America')} className={`destination-btn ${selectedRegion === 'South America' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>South America</p>
            </button>
            <button onClick={() => setSelectedRegion('Africa')} className={`destination-btn ${selectedRegion === 'Africa' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Africa</p>
            </button>
            <button onClick={() => setSelectedRegion('Central America and Caribbean')} className={`destination-btn ${selectedRegion === 'Central America and Caribbean' ? 'selected' : 'hover:bg-[#d1e4fc]'}`}>
              <p>Central America and Caribbean</p>
            </button>
          </div>
        </div>

        <div className="overflow-x-auto">
          <div className="destination-img-div w-[1200px] grid grid-cols-4 gap-2 mt-5 relative md:w-[1200px] sm:w-[1200px] lg:w-full lg:mt-7">
            {filteredDestinations.map((destination, index) => (
              <div key={index}
                onClick={() => handleImageClick(destination)}
                className={`img-div col-span-1 relative h-64 cursor-pointer
                  ${index === 0 ? 'col-span-2' : index === 5 ? 'col-span-2' : index === 1 || 2 || 3 || 4 ? 'col-sapn-1' : null}`}>
                <img
                  className="object-cover w-full h-full rounded"
                  src={destination?.image}
                  alt={destination?.name}
                />
                <div className="country-img-div flex items-center justify-between absolute inset-x-0 p-4 bottom-0 text-white">
                  <div className="country-img-name">
                    <h4 className="place">{destination?.name}</h4>
                    <p className="captal">{destination?.country}</p>
                  </div>
                  <div className="country-img-detail">
                    <p className="start-from">Starting from</p>
                    <h4 className="amount">$ 250,00</h4>
                    <p className="longitude">Est. 0.00239 BTC</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Country selection modal */}
        <Modal
          className="!z-40"
          open={openCountrySelectModal}
          onClose={handleCloseCountrySelect}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            className="flex gap-5 p-3 sm:p-5 rounded-lg w-[90%] sm:w-[530px]"
          >
            <div className="flex justify-between items-center w-full py-1">
              <div className="w-full">
                <p className="text-[#111928] text-[20px] font-medium">
                  Please select your country
                </p>
              </div>
              <div className="border border-gray-300 rounded-lg w-[30px] h-[30px] flex justify-center">
                <IconButton
                  onClick={handleCloseCountrySelect}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            </div>

            <div>
              <p className="text-[#000000] font-normal">
                Due to certain country restrictions, we require your residency
                country before you can search for stays. Please select your
                country to proceed. Thank you for understanding!
              </p>
            </div>

            <div className="flex w-full items-center border border-gray-300 gap-2 rounded-md">
              <CountryAutocomplete
                options={country}
                inputValue={inputValueCountry}
                isoCode={isoCode}
                countrySelect={countrySelect}
                handleInputChange={handleCountryChange}
                handleOptionChange={handleCountryOptionChange}
              />
            </div>

            <Tooltip
              title={
                !countrySelect || !isoCode
                  ? "Please enter your country as it appears in your passport"
                  : ""
              }
              placement="top"
              arrow
            >
              <span className="w-full">
                <button
                  onClick={() => {
                    if (selectedDestination) {
                      searchWithRegion(selectedDestination);
                    }
                  }}
                  className={`items-center text-[#FFFFFF] font-medium py-3 px-2 w-full rounded-lg bg-[#002E8A] min-w-fit ${!countrySelect || !isoCode || loadings ? "opacity-50 cursor-not-allowed" : "hover:bg-[#4826a3]"
                    }`}
                  disabled={!countrySelect || !isoCode || loadings}
                >
                  {loadings ? (
                    <CircularProgress size={18} color="inherit" />
                  ) : (
                    <div className="items-center flex justify-center gap-3">
                      <p>Show Results</p>
                      <img src="/stay_home_img/search_icon.png" alt="" />
                    </div>
                  )}
                </button>
              </span>
            </Tooltip>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default NextDestination;
