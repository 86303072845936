import axios from "axios";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_baseAPI_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete config.headers["Authorization"];
    }
    return config;
  },
  (error) => Promise.reject(error)
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      clearAuthToken();
      window.location.href = "/login";
    } 
    return Promise.reject(error);
  }
);

export function setAuthToken(token) {
  if (token) {
    localStorage.setItem("authToken", token);
    const oneHour = 3600000;
    setTimeout(() => {
      clearAuthToken();
    }, oneHour);
  } else {
    clearAuthToken();
  }
}

export function clearAuthToken() {
  localStorage.removeItem("authToken");
}

export default axiosInstance;
