import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axiosInstance from "../../Utils/axiosConfig";
import { CouponState } from "../../Interfaces/reduxInterfaces";

const initialState: CouponState = {
  selectedCouponId: "",
  couponDetails: null,
  couponError: null,
  couponLoading: false,
};

// Async Thunk to validate and apply a coupon
export const validateAndApplyCoupon = createAsyncThunk(
  "coupons/validateAndApply",
  async (couponCode: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/coupon/verify", {
        couponCode,
      });

      if (response.status === 200 && response.data.status === "success") {
        return response.data;
      } else {
        return rejectWithValue("Coupon validation failed");
      }
    } catch (error: any) {
      return rejectWithValue(error.message || "Something went wrong");
    }
  }
);

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setSelectedCouponId: (state, action: PayloadAction<string>) => {
      state.selectedCouponId = action.payload;
    },
    validateCoupon: (state) => {
      const couponValue = state.selectedCouponId.trim();
      if (!couponValue) {
        state.couponError = null;
      } else if (couponValue.length > 16 || couponValue.length < 16) {
        state.couponError = "Coupon must be 16 characters long";
      } else {
        state.couponError = null;
      }
    },
    resetCouponState: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateAndApplyCoupon.pending, (state) => {
        state.couponLoading = true;
        state.couponError = null;
      })
      .addCase(
        validateAndApplyCoupon.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.couponLoading = false;
          state.couponDetails = action.payload;
        }
      )
      .addCase(
        validateAndApplyCoupon.rejected,
        (state, action: PayloadAction<any>) => {
          state.couponLoading = false;
          state.couponError = 'This coupon cannot be used';
        }
      );
  },
});

export const { setSelectedCouponId, validateCoupon, resetCouponState } = couponSlice.actions;
export default couponSlice.reducer;
