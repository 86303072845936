import React from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../Redux/store';
import PriceSummarySkeleton from '../../Skeleton/priceSummarySkeleton';
import { PriceSummaryProps } from '../../../Interfaces/componentInterfaces';

const PriceSummary: React.FC<PriceSummaryProps> = ({
  prebookStatus,
  prebookData,
}) => {
  const rates = useSelector((state: RootState) => state.currency.cryptoRates);
  const fiatRates = useSelector((state: RootState) => state.currency.fiatRates);
  const selectedCrypto = useSelector(
    (state: RootState) => state.currency.selectedCryptoWithNetwork
  );
  const cryptoRateItem = rates.find(
    (item: any) =>
      item.ccy2 === selectedCrypto.cryptoName &&
      item.blockchain2 === selectedCrypto.blockchain2
  );
  const cryptoRate = cryptoRateItem ? cryptoRateItem.rate : 0;
  const selectedFiat = useSelector(
    (state: RootState) => state.currency.selectedFiat
  );
  const selectedFiatArray = fiatRates.find(
    (item: any) => item.ccy2 === selectedFiat
  );
  const PriceRate = selectedFiat === 'EUR' ? 1 : selectedFiatArray?.rate || 1;
  const precFiatVal = selectedFiatArray ? Number(selectedFiatArray.prec) : 2;

  const roomPriceAmount = prebookData?.prebook_price || 0;
  const { couponDetails } = useSelector((state: RootState) => state.coupon);

  const getConversionRate = (fromCurrency: string, toCurrency: string) => {
    if (fromCurrency === toCurrency) return 1;
    const fromRate = fiatRates.find((item: any) => item.ccy2 === fromCurrency)?.rate || 1;
    const toRate = fiatRates.find((item: any) => item.ccy2 === toCurrency)?.rate || 1;
  
    return fromRate / toRate;
  };

  const adjustPrecision = (value: number, precision: number) => {
    const increment = 1 / 10 ** precision;
    return (value + increment).toFixed(precision);
  };
  
  const calculateDiscountInCrypto = (
    couponDetails: any,
    roomPriceAmount: number,
    cryptoRate: number
  ) => {
    const roomPriceInCrypto = roomPriceAmount / cryptoRate;
  
    if (couponDetails?.couponDetails?.couponType === "Amount") {
      const discountAmount = couponDetails?.couponDetails?.discountAmount || 0;
      const discountCurrency = couponDetails?.couponDetails?.discountAmountCurrency;
  
      const conversionRate = getConversionRate(discountCurrency, "EUR");
      const couponPriceInEUR = discountAmount / conversionRate;
      const couponPriceInCrypto = couponPriceInEUR / cryptoRate;
  
      if (roomPriceInCrypto > couponPriceInCrypto) {
        return adjustPrecision(
          roomPriceInCrypto - couponPriceInCrypto,
          cryptoRateItem?.prec ? Number(cryptoRateItem.prec) : 2
        );
      } else {
        return adjustPrecision(0, cryptoRateItem?.prec ? Number(cryptoRateItem.prec) : 2);
      }
    } else if (couponDetails?.couponDetails?.couponType === "Discount") {
      const discountRate = couponDetails?.couponDetails?.discountRate || 0;
      const discountAmountInCrypto = (roomPriceAmount * discountRate) / (100 * cryptoRate);
      const finalPriceInCrypto = roomPriceInCrypto - discountAmountInCrypto;
      return adjustPrecision(Math.max(finalPriceInCrypto, 0), cryptoRateItem?.prec ? Number(cryptoRateItem.prec) : 2);
    } else {
      return adjustPrecision(Math.max(roomPriceInCrypto, 0), cryptoRateItem?.prec ? Number(cryptoRateItem.prec) : 2);
    }
  };

  const calculateDiscountPrice = (
    couponDetails: any,
    roomPriceAmount: number,
    PriceRate: number,
    precFiatVal: number
  ) => {
    if (couponDetails?.couponDetails?.couponType === 'Amount') {
      const discountAmount = couponDetails?.couponDetails?.discountAmount || 0;
      const discountCurrency = couponDetails?.couponDetails?.discountAmountCurrency;
      const conversionRate = getConversionRate(discountCurrency, selectedFiat);
      return (discountAmount / conversionRate).toFixed(precFiatVal);
    } else if (couponDetails?.couponDetails?.couponType === 'Discount') {
      return (
        (
          (roomPriceAmount * PriceRate * (couponDetails?.couponDetails?.discountRate || 0)) / 100).toFixed(precFiatVal)
      );
    } else {
      return null;
    }
  };

  const calculateTotalPrice = (
    couponDetails: any,
    roomPriceAmount: number,
    PriceRate: number,
    precFiatVal: number
  ) => {
    if (!couponDetails?.couponDetails) {
      // No coupon applied; return the total price in selected fiat
      return (roomPriceAmount * PriceRate).toFixed(precFiatVal);
    }

    if (couponDetails?.couponDetails?.couponType === 'Amount') {
      const discountAmount = couponDetails?.couponDetails?.discountAmount || 0;
      const discountCurrency = couponDetails?.couponDetails?.discountAmountCurrency;
      const conversionRate = getConversionRate(discountCurrency, selectedFiat);
      const couponPrice = discountAmount / conversionRate;

      const roomPrice = roomPriceAmount * PriceRate;
      if (roomPrice > couponPrice) {
        return (roomPrice - couponPrice).toFixed(precFiatVal);
      } else {
        return (0).toFixed(precFiatVal);
      }
    } else if (couponDetails?.couponDetails?.couponType === 'Discount') {
      const discountRate = couponDetails?.couponDetails?.discountRate || 0;
      const roomPrice = roomPriceAmount * PriceRate;
      const discount = (roomPrice * discountRate) / 100;

      return (roomPrice - discount).toFixed(precFiatVal);
    } else {
      // No applicable coupon; return total price
      return (roomPriceAmount * PriceRate).toFixed(precFiatVal);
    }
  };

  return (
    <>
      {prebookStatus === 'success' ? (
        <>
          {prebookData?.pay_on_arrival &&
            prebookData?.pay_on_arrival?.length > 0 &&
            Array.isArray(prebookData.pay_on_arrival) ? (
            <div className="shadow-md rounded-xl bg-[#FFFFFF] p-3 lg:p-5 mt-5 flex flex-col gap-2">
              <p className="font-bold">Pay on arrival</p>
              {prebookData.pay_on_arrival.map((item: any, index: any) => (
                <div
                  key={index}
                  className="flex justify-between items-start gap-2"
                >
                  <span className="">
                    {item.name
                      .replace(/_/g, ' ')
                      .replace(/\b\w/g, (char: string) =>
                        char.toUpperCase()
                      )}
                  </span>
                  <div>
                    <span className="">{item.amount}</span>
                    <span> {item.currency_code}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : null}

          <div className="shadow-md rounded-xl bg-[#FFFFFF] p-3 lg:p-5 mt-5 flex flex-col gap-2">
            <p className="font-bold">Your price summary</p>
            <div className="flex items-start justify-between gap-2">
              <p className="w-[65%]">{prebookData.room_type}</p>
              <div className="flex justify-end w-[35%]">
                <p>
                  {PriceRate &&
                    (roomPriceAmount * PriceRate).toFixed(precFiatVal)}
                  <span> {selectedFiat}</span>
                </p>
              </div>
            </div>

            {couponDetails?.status === 'success' && (
              <div className='flex items-start justify-between gap-2'>
                <p className="w-[65%] text-green-800">Coupon discount</p>
                <div className='flex justify-end w-[35%]'>
                  <p className="text-green-800">
                    <span>- </span>
                    {calculateDiscountPrice(couponDetails, roomPriceAmount, PriceRate, precFiatVal)}
                    <span> {selectedFiat}</span>
                  </p>
                </div>
              </div>
            )}

            <div className="flex items-center justify-between">
              <p>Total</p>
              <p>
                {calculateTotalPrice(couponDetails, roomPriceAmount, PriceRate, precFiatVal)}
                <span> {selectedFiat}</span>
              </p>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <p>Est. price in</p>
              </div>
              <p>
                {calculateDiscountInCrypto(couponDetails, roomPriceAmount, cryptoRate)}
                <span> {selectedCrypto.cryptoName}</span>
              </p>
            </div>
          </div>
        </>
      ) : (
        <PriceSummarySkeleton />
      )}
    </>
  );
};

export default PriceSummary;
