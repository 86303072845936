import React, { useState, useEffect } from "react";
import { useLocation, NavLink, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Modal, IconButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import Select from "@mui/material/Select";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RootState, AppDispatch } from "../../Redux/store";
import {
  fetchCurrencies,
  setSelectedFiat,
  setSelectedCryptoWithNetwork,
} from "../../Redux/Slices/currencySlices";
import LoginUser from "../ModalViews/loginUser";
import MyStayStatus from "../ModalViews/myStayStatus";
import axiosInstance from "../../Utils/axiosConfig";

const Navbar: React.FC = () => {
  const navigate = useNavigate();
  const [openTripModal, setOpenTripModal] = useState(false);
  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleOpenTrip = () => setOpenTripModal(true);
  const handleOpenLogin = () => setOpenLoginModal(true);
  const handleCloseLogin = () => {
    setOpenLoginModal(false);
  };
  const handleCloseTrip = () => {
    setOpenTripModal(false);
  };

  const handleLogout = async () => {
    try {
      await axiosInstance.post("/b2b/logout", {}, { withCredentials: true });

      localStorage.removeItem("isLoggedIn");
      toast.info("You are logged out.");

      setTimeout(() => {
        navigate("/");
        window.location.reload();
      }, 1500);
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  useEffect(() => {
    // On mount, check if user is logged in
    const storedStatus = localStorage.getItem("isLoggedIn");
    if (storedStatus === "true") {
      setIsLoggedIn(true);
    }

    // Optional: add an event listener for localStorage changes
    const storageListener = () => {
      const newVal = localStorage.getItem("isLoggedIn");
      setIsLoggedIn(newVal === "true");
    };
    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, []);

  // Redux stuff
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const {
    selectedFiat,
    selectedCryptoWithNetwork,
    cryptoRates,
    fiatRates,
    status,
  } = useSelector((state: RootState) => state.currency);

  const isHomePage = location.pathname === "/";
  const isPaymentPage = location.pathname === "/payment";
  const isUnsubscribePage = location.pathname === "/unsubscribe";

  const isExcludedPage = ["/", "/payment", "/unsubscribe"].includes(location.pathname);
  const isLoginExcludedPage = ["/hotelsByRegion", "/hotels", "/hotelDetails/prebook", "/payment", "/unsubscribe"].includes(location.pathname);

  useEffect(() => {
    if (status === "idle" && !isExcludedPage) {
      dispatch(fetchCurrencies());
    }
  }, [dispatch, status, isExcludedPage]);

  const fiatSelections =
    fiatRates.length > 0
      ? [fiatRates[0].ccy1, ...fiatRates.map((item: any) => item.ccy2)]
      : [];

  const handleFiatChange = (event: any) => {
    dispatch(setSelectedFiat(event.target.value));
  };

  const handleCryptoChange = (event: any) => {
    const [cryptoName, blockchain2] = event.target.value.split("/");
    dispatch(setSelectedCryptoWithNetwork({ cryptoName, blockchain2 }));
  };

  const cryptoValue = selectedCryptoWithNetwork
    ? `${selectedCryptoWithNetwork.cryptoName}/${selectedCryptoWithNetwork.blockchain2}`
    : "";

  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    borderRadius: "8px",
  };

  return (
    <>
      <Disclosure as="nav" className="bg-white sticky top-0 z-50 shadow-sm">
        <div className="mx-auto px-[1rem] md:px-[1.5rem] w-full xl:container">
          <div className="relative flex h-16 items-center justify-between">
            <div className="absolute inset-y-0 right-0 flex items-center lg:hidden">
              <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open main menu</span>

                <XMarkIcon
                  aria-hidden="true"
                  className="hidden h-6 w-6 group-data-[open]:block"
                />
                <div className="block h-6 w-6 group-data-[open]:hidden">
                  <img src="/stay_home_img/MenuFold.svg" alt="" />
                </div>
              </DisclosureButton>
            </div>
            <div className="flex items-center justify-between sm:items-stretch sm:justify-start">
              <NavLink to="/" className="flex flex-shrink-0 items-center">
                <img
                  alt="Your Company"
                  src="/stay_home_img/Instacoins Travel Logo.svg"
                  className="h-8 w-auto"
                />
              </NavLink>
              <div className="hidden sm:ml-6 lg:block"></div>
            </div>
            <div className="hidden sm:ml-6 lg:block">
              <div className="flex gap-4 items-center">
                <div className="button-div flex gap-2">
                  {!isLoginExcludedPage && (
                    <button
                      onClick={isLoggedIn ? handleLogout : handleOpenLogin}
                      className="text-[#1F2A37] w-[100px] rounded-md border border-[#9CA3AF] px-3 py-2 hover:text-[#324053] hover:border-[#b3bbc9]"
                    >
                      {isLoggedIn ? "Logout" : "Login"}
                    </button>
                  )}

                  {/* LOGIN MODAL */}
                  <Modal
                    open={openLoginModal}
                    onClose={handleCloseLogin}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="w-[90%] md:w-auto md:min-w-[400px]"
                    >
                      <div className="flex flex-col gap-5 w-full">
                        <div className="flex items-center justify-between bg-gray-200 px-3 py-2 md:px-4 md:py-3">
                          <p className="font-bold text-[24px] text-[#002E8A]">
                            Login
                          </p>
                          <div className="border border-gray-300 rounded-lg w-[35px] h-[35px] flex justify-center">
                            <IconButton
                              onClick={handleCloseLogin}
                              aria-label="close"
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </div>
                        <LoginUser closeModal={handleCloseLogin} />
                      </div>
                    </Box>
                  </Modal>

                  {!isUnsubscribePage && (
                    <button
                      onClick={handleOpenTrip}
                      className="text-[#1F2A37] w-[100px] rounded-md border border-[#9CA3AF] px-3 py-2 hover:text-[#324053] hover:border-[#b3bbc9]"
                    >
                      My stay
                    </button>
                  )}

                  {/* MYSTAY MODAL */}
                  <Modal
                    open={openTripModal}
                    onClose={handleCloseTrip}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={style}
                      className="w-[90%] md:w-auto md:max-w-[600px]"
                    >
                      <div className="flex flex-col gap-5 p-3 md:p-5">
                        <div className="flex items-center justify-between">
                          <p className="font-bold text-[18px]">My stay</p>
                          <div className="border border-gray-300 rounded-lg w-[35px] h-[35px] flex justify-center">
                            <IconButton
                              onClick={handleCloseTrip}
                              aria-label="close"
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                        </div>
                        <MyStayStatus closeModal={handleCloseTrip} />
                      </div>
                    </Box>
                  </Modal>
                </div>


                {/* Only show fiat/crypto dropdowns if not on homepage/payment/unsubscribe */}
                {!isHomePage && !isPaymentPage && !isUnsubscribePage && (
                  <div className="drop-down-area flex items-center">
                    <FormControl size="small">
                      <Select
                        value={
                          selectedFiat ||
                          (fiatRates.length > 0 ? fiatRates[0].ccy1 : "")
                        }
                        onChange={handleFiatChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ width: 100, marginRight: 2 }}
                      >
                        {fiatSelections.map((fiat, index) => (
                          <MenuItem key={index} value={fiat}>
                            {fiat}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl size="small">
                      <Select
                        value={cryptoValue || ""}
                        onChange={handleCryptoChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                        sx={{ width: 210 }}
                      >
                        {cryptoRates.map((rate: any) => (
                          <MenuItem
                            key={`${rate.cryptoName}/${rate.blockchain2}`}
                            value={`${rate.cryptoName}/${rate.blockchain2}`}
                          >
                            {`${rate.cryptoName}/${rate.blockchain2}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* MOBILE MENU */}
        {!isUnsubscribePage && (
          <DisclosurePanel className="block lg:hidden">
            <div className="space-y-1 px-[1rem] pb-3 pt-2">
              <div className="flex gap-3 flex-col lg:flex-row">
                <div className="button-div order-2 lg:order-1 flex gap-2 items-center">
                  {!isLoginExcludedPage && (
                    <button
                      onClick={isLoggedIn ? handleLogout : handleOpenLogin}
                      className="text-[#1F2A37] border-[#9CA3AF] rounded-md border px-2 py-1 w-full hover:text-[#324053] hover:border-[#b3bbc9]"
                    >
                      {isLoggedIn ? "Logout" : "Login"}
                    </button>
                  )}

                  {!isUnsubscribePage && (
                    <button
                      onClick={handleOpenTrip}
                      className="text-[#1F2A37] border-[#9CA3AF] rounded-md border px-2 py-1 w-full hover:text-[#324053] hover:border-[#b3bbc9]"
                    >
                      My stay
                    </button>
                  )}
                </div>

                {/* Only show fiat/crypto in mobile if not on homepage/payment/unsubscribe */}
                {!isHomePage && !isPaymentPage && !isUnsubscribePage && (
                  <div className="drop-down-area flex items-center gap-2">
                    <FormControl fullWidth size="small">
                      <Select
                        value={
                          selectedFiat ||
                          (fiatRates.length > 0 ? fiatRates[0].ccy1 : "")
                        }
                        onChange={handleFiatChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {fiatSelections.map((fiat, index) => (
                          <MenuItem key={index} value={fiat}>
                            {fiat}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl fullWidth size="small">
                      <Select
                        value={cryptoValue || ""}
                        onChange={handleCryptoChange}
                        displayEmpty
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        {cryptoRates.map((rate: any) => (
                          <MenuItem
                            key={`${rate.cryptoName}/${rate.blockchain2}`}
                            value={`${rate.cryptoName}/${rate.blockchain2}`}
                          >
                            {`${rate.cryptoName}/${rate.blockchain2}`}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                )}
              </div>
            </div>
          </DisclosurePanel>
        )}
      </Disclosure>
    </>
  );
};

export default Navbar;
