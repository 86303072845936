import React from 'react';
import { Navigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import SearchStay from '../Components/Shared/searchStay';
import StayInfo from '../Components/Views/Home/stayInfo';
import NextDestination from '../Components/Views/Home/nextDestination';
import StayAnywhere from '../Components/Views/Home/stayAnywhere';

const Home: React.FC = () => {
  const authToken = localStorage.getItem('authToken');

  if (!authToken) {
    return <Navigate to='/login' replace />;
  }
  return (
    <>
      <div className='main-page bg-[#F8F8F8]'>
        <div className='back-img'>
          <div className='w-full px-[1rem] md:px-[1.5rem] stay-top-div xl:container py-[8%] mx-auto mx-w-[1536px]'>
            <div className='main-heading-div pb-[8%]'>
              <h1 className='main-heading'>Stay anywhere worldwide!</h1>
            </div>
            <div className='mt-[2%]'>
              <SearchStay />
            </div>
          </div>
        </div>
        <div className='px-[1rem] md:px-[1.5rem] xl:container mx-auto mt-[2%] mx-w-[1536px]'>
          <StayInfo />
          <NextDestination />
          <StayAnywhere />
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Home;
