import React from "react";
import Autocomplete from "@mui/joy/Autocomplete";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Box } from "@mui/material";

import { RegionHotelAutocompleteProps } from "../../Interfaces/componentInterfaces";

const RegionHotelAutocomplete: React.FC<RegionHotelAutocompleteProps> = ({
  options,
  inputValue,
  selectedOption,
  countrySelect,
  setIsFocused,
  handleInputChange,
  handleOptionChange,
}) => {
  return (
    <Autocomplete
      freeSolo
      className="py-2.5 w-full"
      onFocus={() => setIsFocused(true)}
      startDecorator={
        selectedOption?.countryCode ? (
          <div className="flex items-center">
            <img
              className="mr-[8px] w-[20px] h-[15px] object-cover"
              src={`https://flagcdn.com/w20/${selectedOption?.countryCode.toLowerCase()}.png`}
              alt={selectedOption?.countryCode}
            />
            <div className="w-[1px] h-[15px] bg-black"></div>
          </div>
        ) : (
          <LocationOnOutlinedIcon />
        )
      }
      options={options}
      groupBy={(option) => (typeof option === "string" ? "" : option?.group)}
      getOptionLabel={(option) => {
        if (typeof option === "string") {
          return option;
        }
        const name = option?.name || "";
        // const designation = option?.type ? ` ${option?.type}` : "";
        // const countryCode = option?.countryCode
        // ? `-(${option?.countryCode})`
        // : "";
        return `${name}`.trim();
        // ${designation}${countryCode}
      }}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleOptionChange}
      value={selectedOption}
      isOptionEqualToValue={(option, value) => {
        if (typeof option === "string" || typeof value === "string") {
          return option === value;
        }
        return option?.id === value?.id;
      }}
      slotProps={{
        input: {
          placeholder: "Search for a region or hotel",
        },
        root: {
          sx: {
            border: "none !important",
            outline: "none !important",
            boxShadow: "none !important",
            color: countrySelect ? "black" : "red !important",
            "--Input-focusedHighlight": "none !important",
          },
        },
        listbox: {
          className: "!z-0",
          sx: {
            "& > li": {
              display: "flex",
              "& > img": { marginRight: "7px" },
            },
          },
        },
      }}
      renderOption={(props, option) => {
        const { key: _ignoreKey, ownerState, ...otherProps } = props as any;
        const uniqueKey = `${option?.group || ""}-${option?.name || ""}-${option?.type || ""}-${option?.countryCode || ""}-${option?.regionId || ""}`;

        return (
          <Box
            component="li"
            key={uniqueKey}
            sx={{
              "& > img": { flexShrink: 0 },
              display: "flex",
              padding: "6px 8px",
              margin: "1px 0",
              cursor: "pointer",
              alignItems: "start",
              "&:hover": {
                backgroundColor: "#CFD8DC",
              },
            }}
            {...otherProps}
          >
            {option?.countryCode && (
              <img
                loading="lazy"
                className="mr-[8px] mt-[3px] w-[20px] h-[15px] object-cover"
                srcSet={`https://flagcdn.com/w40/${option?.countryCode.toLowerCase()}.png 2x`}
                src={`https://flagcdn.com/w20/${option?.countryCode.toLowerCase()}.png`}
                alt={option?.countryCode}
              />
            )}
            <p className="flex items-start leading-5">
              {option?.name || "Unknown"}
              {option?.type ? ` ${option?.type}` : ""}
              {option?.countryCode ? `-(${option?.countryCode})` : ""}
            </p>
          </Box>
        );
      }}
    />
  );
};

export default RegionHotelAutocomplete;
