import React, { useEffect, useRef, useCallback } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { PopupProps } from '../../../Interfaces/componentInterfaces';

const statusMessages: Record<string, { text: string; color: string; icon: string; description?: string }> = {
    payment_received: { text: "Payment received", color: "#03543F", icon: "/stay_payment_img/check-circle.svg" },
    processing_booking: { text: "Processing booking", color: "#8A2C0D", icon: "/stay_payment_img/clock.svg", description: "We are completing your booking!" },
    booking_completed: { text: "Booking completed", color: "#03543F", icon: "/stay_payment_img/check-circle.svg" },
    payment_expired: { text: "Payment expired", color: "#C62828", icon: "/stay_payment_img/check-circle.svg", description: "The booking expired waiting for your payment. Please search again." },
    booking_error: { text: "Booking error", color: "#C62828", icon: "/stay_payment_img/check-circle.svg", description: "The hotel booking error, please book again." }
};

const Popup: React.FC<PopupProps> = ({ order, timeLeft, onClose }) => {
    const popupRef = useRef<HTMLDivElement>(null);

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    useEffect(() => {
        const autoCloseTimer = setTimeout(handleClose, 5000);

        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                handleClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            clearTimeout(autoCloseTimer);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClose]);

    if (!order?.orderStatus || !statusMessages[order?.orderStatus]) return null;

    const { text, color, icon, description } = statusMessages[order?.orderStatus];

    return (
        <div className="absolute top-20 lg:top-32 left-0 w-full h-full flex items-start justify-center mx-auto z-50">
            <div ref={popupRef} className="bg-[#FFF8F1] p-3 rounded-md shadow-lg flex justify-between items-start gap-3">
                <div className="flex flex-col gap-2">
                    <div className="flex items-center gap-2">
                        <img className="w-[15px] h-[15px]" src={icon} alt="status-icon" />
                        <p className={`font-semibold text-[${color}]`}>{text}</p>
                    </div>
                    {description && <p className={`text-[14px] text-[${color}]`}>{description}</p>}
                    {order?.orderStatus === "booking_completed" && (
                        <div className="leading-6">
                            <p className={`text-[14px] text-[${color}]`}>Your hotel has been booked successfully! Your order number is {order?.order_number}.</p>
                            <p className={`text-[14px] text-[${color}]`}>We sent you a confirmation email at {order?.user_email}.</p>
                        </div>
                    )}
                </div>
                <IconButton onClick={handleClose} aria-label="close">
                    <CloseIcon className="text-[#8A2C0D]" />
                </IconButton>
            </div>
        </div>
    );
};

export default Popup;
