import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ToastContainer } from "react-toastify";
import { format } from "date-fns";

import { fetchHotelsByRegion } from "../Redux/Slices/searchSlice";
import { AppDispatch, RootState } from "../Redux/store";
import SearchStay from "../Components/Shared/searchStay";
import Stepper from "../Components/Shared/stepper";
import SearchResult from "../Components/Views/Search/searchResult";
import Pagination from "../Components/Views/Search/pagination";
import SearchResultSkeleton from "../Components/Skeleton/searchResultSkeleton";
import { Hotel } from "../Interfaces/componentInterfaces";

const Search: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams(location.search);
  const urlPageNo = params.get("page") || "";
  const urlCheckin = params.get("checkin") || "";
  const urlCheckout = params.get("checkout") || "";
  const urlGroup = params.get("group") || "";
  const urlRegionId = params.get("regionId") || "";
  const urlName = params.get("name") || "";
  const urlType = params.get("type") || "";
  const urlCode = params.get("code") || "";
  const urlGuests = params.get("guests");
  const urlSort = params.get("sort") || "popularity";
  const urlResidency = params.get("residency") || "";

  const roomDetails = urlGuests ? JSON.parse(urlGuests) : [];

  let totalAdults = 0;
  let totalChildren = 0;
  roomDetails.forEach((room: { adults: number; children: number[] }) => {
    totalAdults += room.adults;
    totalChildren += room.children.length;
  });
  let totalGuests = totalAdults + totalChildren;

  const [mismatchError, setMismatchError] = useState(false);
  const [showSearchStay, setShowSearchStay] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(parseInt(urlPageNo) || 0);
  const [selectedSortOption, setSelectedSortOption] = useState<string>(urlSort);
  // const [mismatchError, setMismatchError] = useState(false);

  const dateFormat = "dd MMM yyyy";
  const totalPages = 4;

  // Data fetching effect using Redux state management
  // /* eslint-disable */
  useEffect(() => {
    if (!urlPageNo || !urlCheckin || !urlCheckout || !urlRegionId || !urlGroup || !urlName || !urlType || !urlCode || !urlGuests || !urlResidency) {
      setMismatchError(true);
      return;
    }

    // Date validation
    const checkinDate = new Date(urlCheckin).getTime();
    const checkoutDate = new Date(urlCheckout).getTime();
    if (isNaN(checkinDate) || isNaN(checkoutDate) || checkinDate > checkoutDate) {
      setMismatchError(true);
      return;
    }

    // Guests validation
    const parsedGuests = JSON.parse(urlGuests);
    const isValidGuests = Array.isArray(parsedGuests) && parsedGuests.every(guest =>
      guest.hasOwnProperty("rooms") &&
      guest.hasOwnProperty("adults") &&
      guest.hasOwnProperty("children")
    );
    if (!isValidGuests) {
      setMismatchError(true);
      return;
    }

    setMismatchError(false);
    dispatch(
      fetchHotelsByRegion({
        page: currentPage,
        region_id: urlRegionId,
        checkin_date: urlCheckin,
        checkout_date: urlCheckout,
        guest_array: urlGuests ? JSON.parse(urlGuests) : [],
        residency: urlResidency,
        sort: selectedSortOption !== "popularity" ? selectedSortOption : undefined,
      })
    );
  }, [dispatch, urlPageNo, currentPage, urlCheckin, urlCheckout, urlRegionId, urlGroup, urlName, urlType, urlCode, urlGuests, urlResidency, selectedSortOption]);

  // Select search results state from Redux
  const {
    data: hotels,
    loading,
    error,
  } = useSelector((state: RootState) => state.search);

  const handleEditClick = () => {
    setShowSearchStay(!showSearchStay);
  };

  const mapDataToProps = (item: Hotel) => {
    const roomAmenities = item?.amenity_groups?.find(
      (group) => group.group_name === "General"
    )?.amenities;
    // const roomDummyAmenities = ["wifi", "Smoke-free property", "Air conditioning", "Computer", "gym"];
    return {
      hid: Number(item?.hid),
      image: item?.images[0]?.replace("{size}", "1024x768") || "",
      star: item?.star_rating || 0,
      rating: item?.rating || 0,
      reviews: item?.reviews || 0,
      name: item?.name || "No name available",
      address: item?.address || "No address available",
      distance: item?.distance || "",
      amenities: roomAmenities || [],
      id: item?.id || "",
      daily_min: item?.daily_min || 0,
    };
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("page", newPage.toString());
    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  const handleChangeSorting = (event: any) => {
    const sortOption = event.target.value;
    setSelectedSortOption(sortOption);

    const queryParams = new URLSearchParams(location.search);
    if (sortOption === "popularity") {
      queryParams.delete("sort");
    } else {
      queryParams.set("sort", sortOption);
    }
    queryParams.set("page", "0");
    setCurrentPage(0);

    navigate(`${location.pathname}?${queryParams.toString()}`);
  };

  return (
    <>
      <div className="md:hidden">
        <div className="h-10 bg-[#002E8A] flex justify-between w-full xl:container px-[1rem] mx-auto items-center text-[#FFFFFF] font-bold">
          <p className="text-[12px]">
            {urlName && urlName.length > 9 ? `${urlName.substring(0, 10)}..` : urlName}
          </p>
          <p className="text-[12px]">
            {urlCheckin ? `${format(new Date(urlCheckin), dateFormat)}` : ""} - {urlCheckout ? `${format(new Date(urlCheckout), dateFormat)}` : ""}
          </p>
          <p className="text-[12px]">{totalGuests} {totalGuests > 1 ? "Guests" : "Guest"}</p>
          <button onClick={handleEditClick}>
            <img src="/stay_search_img/edit-3.svg" alt="Edit" />
          </button>
        </div>
      </div>

      {showSearchStay && (
        <div className="block md:hidden">
          <SearchStay />
        </div>
      )}

      <div className="bg-[#F8F8F8]">
        <div className="stay-middle-div w-full xl:container mx-auto px-[1rem] md:px-[1.5rem] py-[2%] mx-w-[1536px]">
          <div className="hidden md:block">
            <SearchStay />
          </div>
          <div className="py-4 md:py-10">
            <Stepper />
          </div>

          <div className="flex flex-row items-start mt-3 gap-10">
            <div className="w-full flex flex-col gap-3 md:gap-5 justify-end lg:w-[100%]">
              {!loading && hotels.length > 0 && (
                <div className="flex items-center justify-between gap-2">
                  <p>
                    {hotels.length} of{" "}
                    <span className="font-bold">{hotels.length} properties</span>
                  </p>
                  <div className="shadow">
                    <FormControl sx={{ minWidth: 220 }} fullWidth size="small">
                      <InputLabel id="demo-simple-select-label">
                        Sort Results
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedSortOption}
                        label="Sort Results"
                        onChange={handleChangeSorting}
                      >
                        <MenuItem value="popularity">Most Popular</MenuItem>
                        <MenuItem value="asc">Ascending Price</MenuItem>
                        <MenuItem value="desc">Descending Price</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}

              {!loading ? (
                <>
                  {hotels.length > 0 ? (
                    <>
                      {hotels.map((item: Hotel, index: number) => (
                        <SearchResult key={index} data={mapDataToProps(item)} />
                      ))}
                    </>
                  ) : (
                    <div className="text-center text-red-900 flex flex-col gap-2">
                      <p>No search result found.</p>
                      {mismatchError && <p>
                        The provided region details does not match your search criteria. Please check your search parameters and try again.
                      </p>}
                      {error && <p>{error}</p>}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {Array.from({ length: 9 }).map((_, index) => (
                    <SearchResultSkeleton key={index} />
                  ))}
                </>
              )}

              {!loading && hotels.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  data={hotels}
                  onPageChange={handlePageChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Search;
