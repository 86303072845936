import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { TextField, MenuItem, Select, FormControl } from '@mui/material';

import { RootState } from '../../../Redux/store';
import { setUserDetails, addGuestDetails, clearGuestDetails } from '../../../Redux/Slices/userSlice';
import { Guest } from '../../../Interfaces/componentInterfaces';

const InputGuestDetails: React.FC = () => {
  const dispatch = useDispatch();

  const searchVar = useSelector((state: RootState) => state.searchVar);
  const roomDetails = searchVar.guests;
  const { userDetails, inputErrors } = useSelector(
    (state: RootState) => state.user,
  );

  useEffect(() => {
    dispatch(clearGuestDetails());
    if (roomDetails) {
      roomDetails.forEach((room, roomIndex) => {
        // Add adult guests
        for (let i = 0; i < room.adults; i++) {
          dispatch(
            addGuestDetails({
              roomIndex,
              guest: {
                first_name: "",
                last_name: "",
              },
            })
          );
        }

        // Add child guests
        room.children.forEach((age, childIndex) => {
          dispatch(
            addGuestDetails({
              roomIndex,
              guest: {
                first_name: "",
                last_name: "",
                age,
                is_child: true,
              },
            })
          );
        });
      });
    }
  }, [roomDetails, dispatch]);

  useEffect(() => {
    if (userDetails.guestDetails.length > 0) {
      const updatedGuests = JSON.parse(JSON.stringify(userDetails.guestDetails));

      if (
        updatedGuests[0] &&
        updatedGuests[0].guests[0] &&
        (updatedGuests[0].guests[0].first_name !== userDetails.firstName ||
          updatedGuests[0].guests[0].last_name !== userDetails.lastName)
      ) {
        // Only update if there's a difference
        updatedGuests[0].guests[0].first_name = userDetails.firstName;
        updatedGuests[0].guests[0].last_name = userDetails.lastName;
        dispatch(setUserDetails({ guestDetails: updatedGuests }));
      }
    }
  }, [userDetails.firstName, userDetails.lastName, userDetails.guestDetails, dispatch]);

  // Main guest form handler
  const handleMainGuestChange = (event: { target: any }) => {
    const { name, value } = event.target;
    const formattedValue =
      typeof value === "string"
        ? name === "additionalRequests"
          ? value.trimStart().replace(/\s+/g, " ")
          : value.trim()
        : value;

    dispatch(setUserDetails({ [name]: formattedValue }));
  };

  // Other guest details form handler
  const handleOtherGuestsChange = (roomIndex: number, guestIndex: number, field: keyof Guest, value: string | number) => {
    const trimmedValue = typeof value === "string" ? value.trim() : value;

    const updatedGuests = [...userDetails.guestDetails];
    if (updatedGuests[roomIndex]) {
      updatedGuests[roomIndex] = {
        ...updatedGuests[roomIndex],
        guests: updatedGuests[roomIndex].guests.map((guest, index) =>
          index === guestIndex ? { ...guest, [field]: trimmedValue } : guest
        ),
      };
    }
    dispatch(setUserDetails({ guestDetails: updatedGuests }));
  };

  return (
    <div className='shadow-md rounded-xl bg-[#FFFFFF] p-3 md:p-5 mt-5 mb-5 flex flex-col gap-4'>
      <div className='border-b-2 pb-2'>
        <p className='text-[#000000] text-[24px]'>Main Guests details</p>
      </div>
      <div className='flex items-center w-full gap-4 flex-col md:flex-row'>
        <div className='w-full'>
          {/* <FormControl className="w-full" error={!!errors.bedType}></FormControl> */}
          <FormControl className='w-full flex flex-col gap-2' error={Boolean(inputErrors.title)}>
            <p>Title* {inputErrors.title && (<span className='text-red-600'>({inputErrors.title})</span>)}</p>
            <Select
              size='small'
              name='title'
              value={userDetails.title}
              onChange={handleMainGuestChange}
              autoComplete='off'
              displayEmpty
            >
              <MenuItem value='' disabled>
                Title
              </MenuItem>
              <MenuItem value='Mr'>Mr</MenuItem>
              <MenuItem value='Ms'>Ms</MenuItem>
              <MenuItem value='Mrs'>Mrs</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className='w-full flex flex-col gap-2'>
          <p className='text-[#111928] text-[14px] font-medium'>
            First Name*{' '}
            {inputErrors.firstName && (
              <span className='text-red-600'>({inputErrors.firstName})</span>
            )}
          </p>
          <TextField
            fullWidth
            size='small'
            name='firstName'
            label='First Name'
            value={userDetails.firstName}
            onChange={handleMainGuestChange}
            autoComplete='off'
            error={Boolean(inputErrors.firstName)}
          />
        </div>
      </div>
      <div className='flex items-center w-full gap-4 flex-col md:flex-row'>
        <div className='w-full flex flex-col gap-2'>
          <p className='text-[#111928] text-[14px] font-medium'>
            Middle Name{' '}
            {inputErrors.middleName && (
              <span className='text-red-600'>({inputErrors.middleName})</span>
            )}
          </p>
          <TextField
            fullWidth
            size='small'
            name='middleName'
            label='Middle Name (If Any)'
            value={userDetails.middleName}
            onChange={handleMainGuestChange}
            autoComplete='off'
            error={Boolean(inputErrors.middleName)}
          />
        </div>

        <div className='w-full flex flex-col gap-2'>
          <p className='text-[#111928] text-[14px] font-medium'>
            Last Name*{' '}
            {inputErrors.lastName && (
              <span className='text-red-600'>({inputErrors.lastName})</span>
            )}
          </p>
          <TextField
            fullWidth
            size='small'
            name='lastName'
            label='Last Name'
            value={userDetails.lastName}
            onChange={handleMainGuestChange}
            autoComplete='off'
            error={Boolean(inputErrors.lastName)}
          />
        </div>
      </div>

      <div className='flex w-full gap-4 flex-col md:flex-row'>
        <div className='w-full flex flex-col gap-2'>
          <p className='text-[#111928] text-[14px] font-medium'>
            Phone* {inputErrors.phone && (<span className='text-red-600'>({inputErrors.phone})</span>)}
          </p>
          <div style={{ width: '100%' }}>
            <PhoneInput
              international
              value={userDetails.phone}
              onChange={(value: any) =>
                handleMainGuestChange({ target: { name: 'phone', value } })
              }
              className={`border rounded-md w-full p-2 ${inputErrors.phone
                ? "border-red-600"
                : "border-[#c4c4c4]"
                }`}
            />
          </div>
        </div>
        <div className='w-full flex flex-col gap-2'>
          <p className='text-[#111928] text-[14px] font-medium'>
            Email*{' '}
            {inputErrors.email && (
              <span className='text-red-600'>({inputErrors.email})</span>
            )}
          </p>
          <TextField
            fullWidth
            size='small'
            name='email'
            type='email'
            label='Email'
            value={userDetails.email}
            onChange={handleMainGuestChange}
            autoComplete='off'
            error={Boolean(inputErrors.email)}
          />
          <p className='text-[#374151] text-[14px]'>
            Booking confirmation will be sent to this email address.
          </p>
        </div>
      </div>

      <div className='w-full flex flex-col gap-2'>
        <p className='text-[#111928] text-[14px] font-medium'>
          Additional Requests{' '}
          {inputErrors.additionalRequests && (
            <span className='text-red-600'>
              ({inputErrors.additionalRequests})
            </span>
          )}
        </p>
        <TextField
          fullWidth
          size='small'
          name='additionalRequests'
          label='Add here your additional requests'
          multiline
          value={userDetails.additionalRequests}
          onChange={handleMainGuestChange}
          autoComplete='off'
          error={Boolean(inputErrors.additionalRequests)}
        />

        {/* Dynamic Room Guest Details */}
        {roomDetails.map((room, roomIndex) => (
          <div key={roomIndex} className="w-full mt-4">
            <p className="text-[#000000] text-[24px] mb-1">Room {roomIndex + 1} Guests</p>
            <p className='text-[#4B5563] mb-2'>Please enter details exactly as they appear on your passport/travel document</p>

            {/* Adults Fields */}
            {Array.from({ length: room.adults }).map((_, guestIndex) => (
              <div key={`adult-${guestIndex}`} className="flex-col flex gap-3 mb-3 mt-3 md:flex-row md:items-center">
                <div className='w-[80px] mt-2'>
                  <p className="text-[#000000] text-[17px] lg:mt-5">Adult {guestIndex + 1} :</p>
                </div>
                <div className="flex-col w-full flex gap-3 md:flex-row md:items-center">
                  <div className='w-full flex flex-col gap-2'>
                    <p className='text-[14px] font-medium'>
                      <span className='text-[#111928]'>First Name* </span>
                      {inputErrors.guestDetails?.[roomIndex]?.[guestIndex]?.first_name && (
                        <span className='text-red-600'>({inputErrors.guestDetails?.[roomIndex]?.[guestIndex]?.first_name || ''})</span>
                      )}
                    </p>
                    <TextField
                      fullWidth
                      size="small"
                      name="first name"
                      label={`First Name`}
                      value={roomIndex === 0 && guestIndex === 0 ? userDetails.firstName : userDetails.guestDetails[roomIndex]?.guests[guestIndex]?.first_name || ''}
                      onChange={(e) => handleOtherGuestsChange(roomIndex, guestIndex, 'first_name', e.target.value)}
                      disabled={roomIndex === 0 && guestIndex === 0}
                      error={Boolean(inputErrors.guestDetails?.[roomIndex]?.[guestIndex]?.first_name)}
                    />
                  </div>
                  <div className='w-full flex flex-col gap-2'>
                    <p className='text-[14px] font-medium'>
                      <span className='text-[#111928]'>Last Name* </span>
                      {inputErrors.guestDetails?.[roomIndex]?.[guestIndex]?.last_name && (
                        <span className='text-red-600'>({inputErrors.guestDetails?.[roomIndex]?.[guestIndex]?.last_name || ''})</span>
                      )}
                    </p>
                    <TextField
                      fullWidth
                      size="small"
                      label={`Last Name`}
                      value={roomIndex === 0 && guestIndex === 0 ? userDetails.lastName : userDetails.guestDetails[roomIndex]?.guests[guestIndex]?.last_name || ''}
                      onChange={(e) => handleOtherGuestsChange(roomIndex, guestIndex, 'last_name', e.target.value)}
                      disabled={roomIndex === 0 && guestIndex === 0}
                      error={Boolean(inputErrors.guestDetails?.[roomIndex]?.[guestIndex]?.last_name)}
                    />
                  </div>
                </div>
              </div>
            ))}

            {/* Children Fields */}
            {room.children.map((childAge, childIndex) => (
              <div key={`child-${childIndex}`} className="flex-col flex gap-3 mb-3 mt-2 md:flex-row md:items-center">
                <div className='w-[80px] mt-2'>
                  <p className="text-[#000000] text-[17px] md:mt-5">Child {childIndex + 1} :</p>
                </div>
                <div className="flex-col w-full flex gap-3 md:flex-row md:items-center">
                  <div className='w-full flex flex-col gap-2'>
                    <p className='text-[14px] font-medium'>
                      <span className='text-[#111928]'>First Name* </span>
                      {inputErrors.guestDetails?.[roomIndex]?.[room.adults + childIndex]?.first_name && (
                        <span className='text-red-600'>({inputErrors.guestDetails?.[roomIndex]?.[room.adults + childIndex]?.first_name || ''})</span>
                      )}
                    </p>
                    <TextField
                      fullWidth
                      size="small"
                      label={`First Name`}
                      value={userDetails.guestDetails[roomIndex]?.guests[room.adults + childIndex]?.first_name || ''}
                      onChange={(e) =>
                        handleOtherGuestsChange(roomIndex, room.adults + childIndex, 'first_name', e.target.value)
                      }
                      error={Boolean(inputErrors.guestDetails?.[roomIndex]?.[room.adults + childIndex]?.first_name)}
                    />
                  </div>
                  <div className='w-full flex flex-col gap-2'>
                    <p className='text-[14px] font-medium'>
                      <span className='text-[#111928]'>Last Name* </span>
                      {inputErrors.guestDetails?.[roomIndex]?.[room.adults + childIndex]?.last_name && (
                        <span className='text-red-600'>({inputErrors.guestDetails?.[roomIndex]?.[room.adults + childIndex]?.last_name || ''})</span>
                      )}
                    </p>
                    <TextField
                      fullWidth
                      size="small"
                      label={`Last Name`}
                      value={userDetails.guestDetails[roomIndex]?.guests[room.adults + childIndex]?.last_name || ''}
                      onChange={(e) =>
                        handleOtherGuestsChange(roomIndex, room.adults + childIndex, 'last_name', e.target.value)
                      }
                      error={Boolean(inputErrors.guestDetails?.[roomIndex]?.[room.adults + childIndex]?.last_name)}
                    />
                  </div>
                  <div className='flex flex-col gap-2'>
                    <p className='text-[#111928] text-[14px] font-medium'>Age*</p>
                    <TextField
                      className='w-full lg:w-[80px]'
                      size="small"
                      label="Age"
                      type="number"
                      value={childAge}
                      disabled
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
export default InputGuestDetails;

