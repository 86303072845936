import React from 'react'
import { Skeleton, useMediaQuery } from '@mui/material';

const HotelInfoSkeleton: React.FC = () => {
    const isMobile = useMediaQuery("(max-width: 768px)");
    const skeletonHeight = isMobile ? 150 : 230;
    return (
        <div>
            <div className="flex flex-col gap-5 mt-5">
                <div className="flex flex-col gap-5 w-full justify-between md:flex-row">
                    <div className="w-full md:w-[600px]">
                        <Skeleton height={40} />
                        <Skeleton />
                        <div className="flex gap-10 mt-5">
                            <Skeleton className="w-full" />
                            <Skeleton className="w-full" />
                            <Skeleton className="w-full" />
                        </div>
                    </div>
                    <div className="w-full md:w-[280px]">
                        <div className="flex justify-end">
                            <Skeleton height={40} width={280} />
                        </div>
                        <div className="flex justify-end">
                            <Skeleton width={200} />
                        </div>
                        <div className="flex justify-end">
                            <Skeleton width={160} />
                        </div>
                        <Skeleton
                            variant="rectangular"
                            height={50}
                            className="mt-2 rounded-lg"
                        />
                    </div>
                </div>

                <div>
                    <div className="mt-3 flex flex-col gap-3">
                        <div className="flex flex-col gap-3 md:flex-row">
                            <Skeleton
                                variant="rectangular"
                                height={skeletonHeight}
                                className="w-[100%] rounded-md"
                            />
                            <Skeleton
                                variant="rectangular"
                                height={skeletonHeight}
                                className="w-[100%] rounded-md"
                            />
                        </div>
                        <div className="flex flex-col gap-3 w-full md:flex-row">
                            <div className="flex gap-3 w-full">
                                <Skeleton
                                    variant="rectangular"
                                    height={skeletonHeight}
                                    className="w-[100%] rounded-md"
                                />
                                <Skeleton
                                    variant="rectangular"
                                    height={skeletonHeight}
                                    className="w-[100%] rounded-md"
                                />
                            </div>
                            <div className="flex gap-3 w-full flex-col md:flex-row">
                                <Skeleton
                                    variant="rectangular"
                                    height={skeletonHeight}
                                    className="w-[100%] rounded-md"
                                />
                                <Skeleton
                                    variant="rectangular"
                                    height={skeletonHeight}
                                    className="w-[100%] rounded-md"
                                />
                                <Skeleton
                                    variant="rectangular"
                                    height={skeletonHeight}
                                    className="w-[100%] rounded-md"
                                />
                            </div>
                        </div>
                        <div className='w-full flex flex-col gap-10 md:flex-row'>
                            <div className='w-full md:w-[70%]'>
                                <Skeleton height={40} />
                                <Skeleton />
                                <Skeleton />
                                <div className='mt-3'>
                                    <Skeleton height={40} />
                                    <Skeleton />
                                    <Skeleton />
                                </div>
                                <div className='mt-3'>
                                    <Skeleton height={40} />
                                    <Skeleton />
                                </div>
                                <div className='w-[60%] mt-3'>
                                    <Skeleton height={50} />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </div>
                            </div>
                            <div className='w-full md:w-[30%]'>
                                <div className='flex gap-5 w-ful'>
                                    <div className='w-full'>
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton />
                                    </div>
                                    <div className='w-full'>
                                        <Skeleton />
                                        <Skeleton />
                                        <Skeleton />
                                    </div>
                                </div>
                                <div className='mt-3 flex flex-col gap-2'>
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                    <div className='flex justify-center mt-4'>
                                        <Skeleton width={150} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full'>
                    <div>
                        <Skeleton height={60} />
                    </div>
                    <div className='flex gap-5 mt-4 w-full'>
                        <div className='w-[30%]'>
                            <Skeleton
                                variant="rectangular"
                                height={230}
                                className="w-[100%] rounded-xl" />
                        </div>
                        <div className='w-[70%]'>
                            <div className='w-full gap-8 flex mb-3'>
                                <div className='w-full'>
                                    <Skeleton height={30} />
                                </div>
                                <div className='w-full'>
                                    <Skeleton height={30} />
                                </div>
                                <div className='w-full'>
                                    <Skeleton height={30} />
                                </div>
                                <div className='w-full'>
                                    <Skeleton height={30} />
                                </div>
                                <div className='w-full'>
                                    <Skeleton height={30} />
                                </div>
                            </div>

                            <div className='w-full gap-8 flex'>
                                <div className='w-full'>
                                    <Skeleton height={30} />
                                </div>
                                <div className='w-full'>
                                    <Skeleton height={30} />
                                </div>
                                <div className='w-full'>
                                    <Skeleton height={30} />
                                </div>
                                <div className='w-full'>
                                    <Skeleton height={30} />
                                </div>
                                <div className='w-full'>
                                    <Skeleton height={30} />
                                </div>
                            </div>
                            <div className='mt-10'>
                                <Skeleton
                                    variant="rectangular"
                                    height={50}
                                    className="w-[30%] rounded-xl" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mt-5'>
                    <Skeleton
                        variant="rectangular"
                        height={30}
                        className="w-[30%] rounded-xl" />

                    <div className='flex gap-20 w-full mt-3'>
                        <div className=' w-full'>
                            <Skeleton height={30} />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                        <div className=' w-full'>
                            <Skeleton height={30} />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                        <div className=' w-full'>
                            <Skeleton height={30} />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                        <div className=' w-full'>
                            <Skeleton height={30} />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default HotelInfoSkeleton;