import React from 'react'
import parse from 'html-react-parser';

import { HotelHouseRulesProps } from '../../../Interfaces/componentInterfaces';

const HotelHouseRules: React.FC<HotelHouseRulesProps> = ({ data }) => {
  const checkInTime = data?.check_in_time || "00:00:00";
  const [checkInHour] = checkInTime.split(':').map(Number);
  const checkInProgressWidth = `${(checkInHour / 24) * 100}%`;

  const checkOutTime = data?.check_out_time || "00:00:00";
  const [checkOutHour] = checkOutTime.split(':').map(Number);
  const checkOutProgressWidth = `${(checkOutHour / 24) * 100}%`;

  return (
    <div id="houseRules">
      <div className='mt-8 mx-auto flex justify-center'>
        <div className='w-full lg:w-[60%] flex flex-col gap-8'>
          <div className='w-full lg:w-[60%] flex flex-col gap-4 md:gap-8'>
            <p className='text-[20px] font-bold'>House rules</p>
            <p className='text-[#001A4D]'>{data?.name} takes special requests - add in the next step!</p>
            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2 w-[150px] lg:w-[25%]'>
                <img src="/stay_hotel_img/calendar.svg" alt="" />
                <p>Check-in</p>
              </div>
              <div className='w-[75%]'>
                <div className='flex items-center justify-end gap-24'>
                  <p>{data?.check_in_time}</p>
                  <p className='text-end'>00:00</p>
                </div>
                <div className='w-[100%] h-[25px] border border-[#002E8A] bg-[#002E8A] rounded-full flex overflow-hidden justify-start'>
                  <div
                    className='h-[25px] bg-[#FFFFFF]'
                    style={{ width: checkInProgressWidth }}
                  ></div>
                </div>
                <p className='text-center'>{data?.check_in_time} - 00:00</p>
                <div>
                </div>
              </div>
            </div>

            <div className='flex items-center justify-between'>
              <div className='flex items-center gap-2 w-[150px] lg:w-[25%]'>
                <img src="/stay_hotel_img/calendar.svg" alt="" />
                <p>Check-out</p>
              </div>
              <div className='w-[75%]'>
                <div className='flex items-center justify-end gap-24'>
                  <p>{data?.check_out_time}</p>
                  <p>00:00</p>
                </div>
                <div className='w-[100%] h-[25px] border border-[#002E8A] bg-[#002E8A] rounded-full flex overflow-hidden justify-start'>
                  <div
                    className='h-[25px] bg-[#FFFFFF]'
                    style={{ width: checkOutProgressWidth }}
                  ></div>
                </div>
                <p className='text-center'>{data?.check_out_time} - 00:00</p>
              </div>
            </div>
          </div>

          {/* Hotel Policies Table */}
          <div className="mt-3 md:mt-8">
            {/* Extra Info Section */}
            {typeof data?.metapolicy_extra_info === "string" && (
              <div>{parse(data.metapolicy_extra_info)}</div>
            )}

            {/* Dynamic Policies Table */}
            {data?.metapolicy_struct && Object.keys(data.metapolicy_struct).length > 0 && (
              <table>
                <tbody className="info-tbody">
                  {Object.entries(data.metapolicy_struct).map(([policyKey, policyData]) => (
                    Array.isArray(policyData) && policyData.length > 0 ? (
                      <tr key={policyKey}>
                        <td className="font-bold pt-2 pl-0 pr-5 align-top lg:min-w-[100px] capitalize">
                          {policyKey.replace(/_/g, " ")}:
                        </td>
                        <td className="pl-2">
                          {policyData.map((item: Record<string, any>, index: number) => (
                            <div key={index} className="pt-0 leading-normal">
                              {Object.entries(item).map(([key, value], idx, arr) => (
                                <span key={key} className="mr-2">
                                  {key.replace(/_/g, " ")}: {value || "N/A"}
                                  {idx !== arr.length - 1 && ", "}
                                </span>
                              ))}
                            </div>
                          ))}
                        </td>
                      </tr>
                    ) : null
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default HotelHouseRules;