import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SearchStateVar } from "../../Interfaces/reduxInterfaces";

const initialState: SearchStateVar = {
  checkin: "",
  checkout: "",
  guests: [{ rooms: 1, adults: 2, children: [] }],
  group: "",
  name: "",
  regionId: "",
  hid: "",
  id: "",
  type: "",
  countryCode: "",
  residency: "",
  residencyCode: "",
};

export const searchVarSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchParams: (state, action: PayloadAction<Partial<SearchStateVar>>) => {
      state.checkin = action.payload.checkin
        ? new Date(action.payload.checkin).toISOString()
        : null;
      state.checkout = action.payload.checkout
        ? new Date(action.payload.checkout).toISOString()
        : null;
      state.guests = action.payload.guests ?? state.guests;
      state.group = action.payload.group ?? state.group;
      state.name = action.payload.name ?? state.name;
      state.regionId = action.payload.regionId ?? state.regionId;
      state.hid = action.payload.hid ?? state.hid;
      state.id = action.payload.id ?? state.id;
      state.type = action.payload.type ?? state.type;
      state.countryCode = action.payload.countryCode ?? state.countryCode;
      state.residency = action.payload.residency ?? state.residency;
      state.residencyCode = action.payload.residencyCode ?? state.residencyCode;
    },
  },
});

export const { setSearchParams } = searchVarSlice.actions;
export default searchVarSlice.reducer;
