import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import { format } from 'date-fns';

import { RootState } from '../../../Redux/store';
import { searchResultAmenities } from '../../Shared/allImages';
import { SearchResultProps } from '../../../Interfaces/componentInterfaces';

const SearchResult: React.FC<SearchResultProps> = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const urlCheckin = params.get('checkin') || '';
  const urlCheckout = params.get('checkout') || '';
  const urlCode  = params.get('code') || '';
  const urlResidencyCode = params.get('residency') || '';
  const urlGuests = params.get("guests") || '';

  // Parse the roomDetails and cast it to RoomDetail[]
  // const roomDetails: RoomDetail[] = JSON.parse(urlGuests || '[]');

  // // Map over roomDetails and remove the 'rooms' property
  // const parsedRoomDetails: ParsedRoomDetail[] = roomDetails.map(
  //   ({ rooms, ...rest }) => rest,
  // );

  const rates = useSelector((state: RootState) => state.currency.cryptoRates);
  const fiatRates = useSelector((state: RootState) => state.currency.fiatRates);
  const selectedCrypto = useSelector(
    (state: RootState) => state.currency.selectedCryptoWithNetwork,
  );
  const cryptoRateItem = rates.find(
    (item: any) => item.ccy2 === selectedCrypto.cryptoName,
  );

  const cryptoRate = cryptoRateItem ? cryptoRateItem.rate : 'Rate not found';
  const selectedFiat = useSelector(
    (state: RootState) => state.currency.selectedFiat,
  );
  let selectedFiatArray = fiatRates.find(
    (item: any) => item.ccy2 === selectedFiat,
  );

  let PriceRate = selectedFiat === 'EUR' ? 1 : selectedFiatArray?.rate;
  let precFiatVal = selectedFiatArray ? Number(selectedFiatArray?.prec) : 2;

  const [showAllAmenities, setShowAllAmenities] = useState(false);
  const displayedAmenities = showAllAmenities
    ? data?.amenities
    : data?.amenities.slice(0, 4);
  const toggleShowAllAmenities = () => {
    setShowAllAmenities((prevState) => !prevState);
  };
  // console.log(displayedAmenities);

  const handleHotelClick = () => {
    const params = new URLSearchParams({
      checkin: urlCheckin ? format(new Date(urlCheckin), 'yyyy-MM-dd') : '',
      checkout: urlCheckout ? format(new Date(urlCheckout), 'yyyy-MM-dd') : '',
      group: "Hotels",
      hid: data && (data?.hid).toString(),
      name: data && data?.name,
      code: urlCode,
      guests: urlGuests,
      residency: urlResidencyCode ? urlResidencyCode : "mt"
    });
    navigate(`/hotels?${params.toString()}`);
  };

  const fiatPrice = data?.daily_min;

  const formatPrice = (price: any, conversionRate: any) => {
    return Number(price) / Number(conversionRate);
  };

  const adjustPrecision = (value: number, precision: number) => {
    const increment = 1 / 10 ** precision;
    return (value + increment).toFixed(precision);
  };

  return (
    <>
      <div className='hidden md:block'>
        <div className='flex gap-1 w-full h-full shadow rounded-xl bg-[#FFFFFF]'>
          <div
            onClick={handleHotelClick}
            className='flex w-[415px] h-[220px] rounded-bl-lg rounded-tl-lg cursor-pointer'
          >
            <img
              className='rounded-bl-lg rounded-tl-lg object-cover'
              src={data?.image}
              alt=''
            />
          </div>

          <div className='flex justify-between p-4 w-full gap-2'>
            <div className='w-full flex flex-col gap-2'>
              <div className='flex flex-col gap-1'>
                <div className='flex items-center gap-2'>
                  {[...Array(data?.star)].map((_, index) => (
                    <img
                      key={`filled-${index}`}
                      className='w-[12px] h-[12px]'
                      src='/stay_search_img/s-star.svg'
                      alt='filled star'
                    />
                  ))}
                  {[...Array(5 - data?.star)].map((_, index) => (
                    <img
                      key={`empty-${index}`}
                      className='w-[15px] h-[15px]'
                      src='/stay_search_img/s-star-blank.svg'
                      alt='empty star'
                    />
                  ))}
                </div>
                <div className='flex'>
                  <div onClick={handleHotelClick}>
                    <p className='text-[#000F2E] text-[24px] font-bold leading-8 cursor-pointer'>
                      {data?.name?.length > 80
                        ? `${data?.name?.substring(0, 78)}...`
                        : data?.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-4'>
                <div className='flex items-center gap-2'>
                  <img
                    className='w-[21px] h-[22px]'
                    src='/stay_search_img/map-pin.svg'
                    alt=''
                  />
                  <div className='flex flex-col'>
                    <p className='text-[14px]'>
                      {data?.address?.length > 80
                        ? `${data?.address?.substring(0, 78)}...`
                        : data?.address}
                    </p>
                    <p className='text-[12px]'>{data?.distance}</p>
                  </div>
                </div>
                <div className='flex w-[90%] items-center flex-wrap gap-5 pt-3'>
                  {displayedAmenities.map((amenity, index) => (
                    <Tooltip key={index} title={amenity} arrow>
                      <div>
                        <img
                          className='w-[24px] h-[24px]'
                          src={
                            (searchResultAmenities as any)[amenity]?.icon ||
                            '/stay_search_img/amenities/Check.svg'
                          }
                          alt={amenity}
                        />
                      </div>
                    </Tooltip>
                  ))}
                  {data?.amenities?.length > 4 && (
                    <button onClick={toggleShowAllAmenities} className='flex items-center gap-2'>
                      <span className='text-[12px]'>
                        {showAllAmenities ? 'Show less' : 'Show all'}
                      </span>
                      <img
                        className='w-[20px] h-[20px]'
                        src='/stay_search_img/expand.svg'
                        alt=''
                      />
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className='flex w-[260px]'>
              <div className='w-full flex flex-col justify-around gap-10'>
                <div className='text-end'>
                  {fiatPrice && PriceRate !== undefined ? (
                    <>
                      <p className='text-[24px] font-bold'>
                        <span className='text-[14px]'>from </span>
                        {(fiatPrice * PriceRate).toFixed(precFiatVal)}{' '}
                        {selectedFiat}
                      </p>
                      <p className='text-[#002E8A] text-[14px] font-bold'>
                        Est.{' '}
                        {cryptoRate
                          ? adjustPrecision(
                            formatPrice(fiatPrice, cryptoRate),
                            cryptoRateItem?.prec ? Number(cryptoRateItem.prec) : 2
                          ) : ''}{' '}
                        {selectedCrypto.cryptoName}
                      </p>
                      <p className='text-[12px] font-medium text-[#000F2E]'>
                        price per night
                      </p>
                    </>
                  ) : (
                    <span className='text-[18px]'>
                      Not available at this time
                    </span>
                  )}
                </div>
                <div className='flex justify-end'>
                  {fiatPrice && PriceRate !== undefined ? (
                    <button
                      onClick={handleHotelClick}
                      className='bg-[#002E8A] hover:bg-[#4826a3] text-[#FFFFFF] font-medium rounded-lg py-3 w-full md:w-[170px]'
                    >
                      Book Now
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Version */}
      <div className='flex flex-col w-full gap-4 shadow-md rounded-lg md:hidden p-3'>
        <div className='flex items-center gap-5'>
          <div
            onClick={handleHotelClick}
            className='flex w-[120px] h-[70px] cursor-pointer rounded-md'
          >
            <img className='w-full rounded-md' src={data?.image} alt='' />
          </div>
          <div className='flex flex-col'>
            <div onClick={handleHotelClick} className='cursor-pointer'>
              <p className='text-[#000F2E] text-[18px] font-bold'>
                {data?.name}
              </p>
            </div>
            <div className='flex items-center gap-2'>
              {[...Array(data?.star)].map((_, index) => (
                <img
                  key={`filled-${index}`}
                  className='w-[18px] h-[18px]'
                  src='/stay_search_img/rating-star-fill.svg'
                  alt='filled star'
                />
              ))}
              {[...Array(5 - data?.star)].map((_, index) => (
                <img
                  key={`empty-${index}`}
                  className='w-[18px] h-[18px]'
                  src='/stay_search_img/rating-star-blank.svg'
                  alt='empty star'
                />
              ))}
            </div>
          </div>
        </div>
        <div className='flex items-center w-full justify-between gap-4'>
          <div className='flex items-center gap-2'>
            <img
              className='w-[18px] h-[22px]'
              src='/stay_search_img/map-pin.svg'
              alt=''
            />
            <div className='flex flex-col'>
              <p className='text-[12px] leading-4'>
                {data?.address?.length > 80
                  ? `${data?.address.substring(0, 78)}...`
                  : data.address}
              </p>
              <p className='text-[12px]'>{data?.distance}</p>
            </div>
          </div>
        </div>
        <div className='flex w-full justify-between'>
          <div className='flex flex-col gap-6 w-full'>
            <div className='flex items-center flex-wrap gap-3'>
              {displayedAmenities.map((amenity, index) => (
                <Tooltip key={index} title={amenity} arrow>
                  <div>
                    <img
                      className='w-[24px] h-[24px]'
                      src={
                        (searchResultAmenities as any)[amenity]?.icon ||
                        '/stay_search_img/amenities/check.svg'
                      }
                      alt={amenity}
                    />
                  </div>
                </Tooltip>
              ))}
            </div>
            {data?.amenities?.length > 4 && (
              <div className='flex'>
                <button onClick={toggleShowAllAmenities} className='flex items-center gap-3'>
                  <span className='text-[12px]'>
                    {showAllAmenities ? 'Show less' : 'Show all'}
                  </span>
                  <img
                    className='w-[16px] h-[16px]'
                    src='/stay_search_img/expand.svg'
                    alt=''
                  />
                </button>
              </div>
            )}
          </div>

          <div className='w-full flex flex-col text-right'>
            {fiatPrice && PriceRate !== undefined ? (
              <div className='flex flex-col leading-5'>
                <p className='text-[14px] font-medium text-[#000F2E]'>
                  price per night
                </p>
                <p className='text-[#000F2E] font-bold'>
                  <span className='text-[16px]'>from </span>
                  <span className='text-[18px]'>
                    {(fiatPrice * PriceRate).toFixed(precFiatVal)}{' '}
                    {selectedFiat}
                  </span>
                </p>
                <p className='text-[#002E8A] font-bold'>
                  <span className='text-[12px]'>est </span>
                  <span className='text-[14px]'>
                    {cryptoRate
                      ? adjustPrecision(
                        formatPrice(fiatPrice, cryptoRate),
                        cryptoRateItem?.prec ? Number(cryptoRateItem.prec) : 2
                      ) : ''}{' '}
                    {selectedCrypto.cryptoName}
                  </span>
                </p>
              </div>
            ) : (
              <span className='text-[14px] text-[#000F2E]'>
                Not available at this time
              </span>
            )}
          </div>
        </div>
        <div className='flex w-full mt-3'>
          {fiatPrice && PriceRate !== undefined ? (
            <button
              onClick={handleHotelClick}
              className='bg-[#002E8A] hover:bg-[#4826a3] w-full text-[#FFFFFF] font-medium rounded-lg px-3 py-2 md:py-3 items-center flex justify-center'
            >
              Choose room
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default SearchResult;
